import { observer } from 'mobx-react';
import rootStore from './stores/rootStore';
import { RootStoreProvider } from './stores/context';
import { RouterConfig } from './navigation/RouterConfig';

function App() {
	return (
		<RootStoreProvider value={rootStore}>
			<RouterConfig />
		</RootStoreProvider>
	);
}

export default observer(App);
