import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toJS } from 'mobx';
import { IconButton, Select, MenuItem } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores/context';
import { PageHeading } from '../../components/shared/PageHeading';
import { ProductsPagination } from './components/ProductsPagination';
import { ProductsGrid } from './layouts/ProductsGrid';
import { Breadcrumbs } from '../../components/shared/Breadcrumbs';
import { scrollToTop } from '../../utils/helpers';
import './index.scss';

export const ProductsPage = observer(() => {
	const location = useLocation();
	const { telegramBotUsername } = useParams();
	const {
		productsStore: {
			products,
			searchQuery,
			setSearchQuery,
			resetSearchQuery,
			getAll,
			setIsFiltersMenuOpen,
			meta,
		},
		appStore: {
			productsSwitchState,
			setProductsSwitchState,
			setIsOverlayVisible,
		},
		shopsStore,
		categoriesStore: { categories },
		attributesStore: { getAttributes },
	} = useStores();
	const { t } = useTranslation();

	const newlyListed = t('products-page.order-by-items.newly-listed');
	const priceLowestFirst = t('products-page.order-by-items.price-lowest-first');
	const priceHighestFirst = t(
		'products-page.order-by-items.price-highest-first',
	);
	const mostPopular = t('products-page.order-by-items.most-popular');

	const [category, setCategory] = useState(location.state || null);
	const items = toJS(products);

	useEffect(() => {
		if (shopsStore.currentShop?.id) {
			const params = new URLSearchParams(window.location.search);
			const categoryId = location.state?.id || params.get('categoryId');

			setCategory(categories.find((item) => item.id === +categoryId));

			const attributes = Object.fromEntries(
				toJS(searchQuery).attributes.entries(),
			);
			getAll({
				...searchQuery,
				attributes:
					Object.keys(attributes).length > 0 ? JSON.stringify(attributes) : {},
				categoryId,
				shopId: shopsStore.currentShop?.id,
			});
		}
	}, [
		JSON.stringify(searchQuery),
		location.state,
		shopsStore.currentShop?.id,
		window.location.search,
	]);

	useEffect(() => {
		if (category) {
			resetSearchQuery();
			getAttributes(category?.id);
		}
	}, [category]);

	const handleUpdateOrderBy = (event) => {
		scrollToTop();
		setSearchQuery({ orderBy: event.target.value, page: 1 });
	};

	const handleChangePage = (_, pageNumber) => {
		scrollToTop();
		setSearchQuery({ page: pageNumber });
	};

	const handleOpenFiltersMenu = () => {
		setIsOverlayVisible(true);
		setIsFiltersMenuOpen(true);
	};

	const getActiveCategoryId = () => {
		if (category.subcategories.length > 0) {
			return category.id;
		}
		return category.parentId ? category.parentId : null;
	};

	const getHeadingText = () => {
		if (category?.title) {
			if (category.title === 'default') {
				return t('categories-page.default');
			}
			return `${t('products-page.up-title')} ${category.title.toLowerCase()}`;
		}

		return t('products-page.title');
	};

	return (
		<div className="products-page">
			{category?.parentId && <Breadcrumbs category={category} />}
			<div
				className="products-page__header"
				style={{ marginTop: !category?.parentId ? '16px' : '0' }}
			>
				<PageHeading headingText={getHeadingText()} margin="0" />
				{category?.id && (
					<Link
						className="breadcrumbs__link"
						to={`/s/${telegramBotUsername}/categories`}
						state={
							getActiveCategoryId()
								? { activeCategoryId: getActiveCategoryId() }
								: null
						}
					>
						<ArrowBackRoundedIcon />
					</Link>
				)}
			</div>
			<div className="products-page__actions-container">
				<div className="products-page__actions">
					{category?.id ? (
						<button
							className="products-page__filter-button"
							aria-label="Open filters"
							type="button"
							onClick={handleOpenFiltersMenu}
						>
							<span>
								<FilterAltOutlinedIcon />
							</span>
							{t('products-page.filter-button')}
						</button>
					) : (
						<Link
							className="products-page__filter-button"
							aria-label="Open filters"
							type="button"
							to={`/s/${telegramBotUsername}/categories`}
						>
							<span>
								<CategoryOutlinedIcon />
							</span>
							{t('side-menu.categories-button')}
						</Link>
					)}

					<Select
						id="order-by"
						value={searchQuery.orderBy}
						className="products-page__order-select"
						onChange={handleUpdateOrderBy}
						sx={{
							'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
								borderColor: 'var(--link-color)',
							},
						}}
					>
						<MenuItem value="cheap">{priceLowestFirst}</MenuItem>
						<MenuItem value="expensive">{priceHighestFirst}</MenuItem>
						<MenuItem value="novelty">{newlyListed}</MenuItem>
						<MenuItem value="popular">{mostPopular}</MenuItem>
					</Select>
				</div>
				<div className="products-page__switch">
					<IconButton
						className="products-page__switch-button"
						disabled={productsSwitchState === 6}
						onClick={() => setProductsSwitchState(6)}
					>
						<GridViewIcon />
					</IconButton>
					<IconButton
						className="products-page__switch-button"
						disabled={productsSwitchState === 12}
						onClick={() => setProductsSwitchState(12)}
					>
						<FormatListBulletedIcon />
					</IconButton>
				</div>
			</div>
			<ProductsGrid
				products={items}
				fromCategory={Boolean(category)}
				shopFormatTitle={shopsStore.currentShop.enableFormatTitle}
				currencySymbol={shopsStore.currentShop.currencySymbol}
			/>
			{items.length !== 0 && (
				<ProductsPagination
					totalPages={meta.totalPages || 0}
					currentPage={searchQuery.page || 1}
					handleChangePage={handleChangePage}
				/>
			)}
		</div>
	);
});
