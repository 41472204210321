import { types } from 'mobx-state-tree';

const Category = types.model('Category', {
	id: types.maybeNull(types.identifierNumber),
	title: types.maybeNull(types.string),
	orderBy: types.optional(types.string, 'popular'),
	parentId: types.maybeNull(types.number),
	parent: types.maybeNull(types.late(() => Category)),
	subcategories: types.optional(types.array(types.late(() => Category)), []),
	validParentCategories: types.optional(
		types.array(types.late(() => Category)),
		[],
	),
});

export default Category;
