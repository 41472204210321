import { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { HeaderFilter } from './components/HeaderFilter';
import { FiltersMenu } from './components/FiltersMenu';
import { PriceFilter } from './components/PriceFilter';
import { StatusFilter } from './components/StatusFilter';
import { FooterFilter } from './components/FooterFilter';
import { DiscountFilter } from './components/DiscountFilter';
import { useStores } from '../../../../stores/context';
import { OtherFilter } from './components/OtherFilter';
import './index.scss';

export const ProductsFilters = observer(() => {
	const { productsStore, appStore } = useStores();
	const { t } = useTranslation();
	const [openedFilter, setOpenedFilter] = useState('none');

	const handleCloseFilterMenu = () => {
		productsStore.setIsFiltersMenuOpen(false);
		appStore.setIsOverlayVisible(false);
		setOpenedFilter('none');
	};

	const getFilterTab = () => {
		switch (openedFilter) {
			case 'price':
				return (
					<>
						<HeaderFilter
							headerText={t('products-filters.header-text-price')}
							goBackAction={() => setOpenedFilter('none')}
							resetButtonText={t('products-filters.reset-button-partial')}
							resetFilters={() =>
								productsStore.setSearchQuery({ price: '', page: 1 })
							}
						/>
						<div className="product-filters">
							<PriceFilter goBackAction={() => setOpenedFilter('none')} />
						</div>
						<FooterFilter
							goBackAction={() => setOpenedFilter('none')}
							showProductsAction={handleCloseFilterMenu}
						/>
					</>
				);
			case 'sellStatus':
				return (
					<>
						<HeaderFilter
							headerText={t('products-filters.header-text-sell-status')}
							goBackAction={() => setOpenedFilter('none')}
							resetButtonText={t('products-filters.reset-button-partial')}
							resetFilters={() =>
								productsStore.setSearchQuery({ sellStatus: '', page: 1 })
							}
						/>
						<div className="product-filters">
							<StatusFilter />
						</div>
						<FooterFilter
							goBackAction={() => setOpenedFilter('none')}
							showProductsAction={handleCloseFilterMenu}
						/>
					</>
				);
			case 'discountOnly':
				return (
					<>
						<HeaderFilter
							headerText={t('products-filters.header-text-discount')}
							goBackAction={() => setOpenedFilter('none')}
							resetButtonText={t('products-filters.reset-button-partial')}
							resetFilters={() =>
								productsStore.setSearchQuery({ discountOnly: false, page: 1 })
							}
						/>
						<div className="product-filters">
							<DiscountFilter />
						</div>
						<FooterFilter
							goBackAction={() => setOpenedFilter('none')}
							showProductsAction={handleCloseFilterMenu}
						/>
					</>
				);
			case 'none':
				return (
					<>
						<HeaderFilter
							headerText={t('products-filters.header-text-main')}
							goBackAction={handleCloseFilterMenu}
							resetButtonText={t('products-filters.reset-button-all')}
							resetFilters={() =>
								productsStore.setSearchQuery({
									price: '',
									sellStatus: '',
									discountOnly: false,
									attributes: {},
									page: 1,
								})
							}
						/>
						<div className="product-filters__body">
							<FiltersMenu setOpenedFilter={setOpenedFilter} />
						</div>
						<FooterFilter showProductsAction={handleCloseFilterMenu} />
					</>
				);
			default:
				return (
					<>
						<HeaderFilter
							headerText={openedFilter}
							goBackAction={() => setOpenedFilter('none')}
							resetButtonText={t('products-filters.reset-button-partial')}
							resetFilters={() =>
								productsStore.setSearchQuery({ attributes: {}, page: 1 })
							}
						/>
						<div className="product-filters">
							<OtherFilter name={openedFilter} />
						</div>
						<FooterFilter
							goBackAction={() => setOpenedFilter('none')}
							showProductsAction={handleCloseFilterMenu}
						/>
					</>
				);
		}
	};

	return getFilterTab();
});
