import { types } from 'mobx-state-tree';
import ProductAttribute from '../../pureModels/attribute-model';
import { ImageModel } from '../../sharedModels';

const ShippingAddress = types.model('ShippingAddress', {
	id: types.maybeNull(types.identifierNumber),
	type: types.optional(types.string, ''),
	city: types.maybeNull(types.string),
	npDepartment: types.maybeNull(types.string),
	shopLocation: types.maybeNull(types.string),
	destination: types.maybeNull(types.string),
	street: types.optional(types.string, ''),
	building: types.optional(types.string, ''),
	entrance: types.optional(types.string, ''),
	apartment: types.optional(types.string, ''),
});

const Product = types.model('Product', {
	id: types.maybeNull(types.identifierNumber),
	sku: types.optional(types.string, ''),
	title: types.optional(types.string, ''),
	price: types.optional(types.number, 0),
	discountPrice: types.maybeNull(types.number),
	stockControl: types.optional(types.boolean, true),
	images: types.optional(types.array(ImageModel), []),
	variantParentId: types.maybeNull(types.number),
	attributes: types.maybeNull(types.array(ProductAttribute)),
});

const OrderItem = types.model('OrderItem', {
	id: types.maybeNull(types.identifierNumber),
	quantity: types.maybeNull(types.number),
	subTotalAmount: types.maybeNull(types.number),
	title: types.maybeNull(types.string),
	product: types.maybeNull(Product),
});

const Order = types.model('Order', {
	id: types.maybeNull(types.identifierNumber),
	isPayed: types.maybeNull(types.boolean),
	fullName: types.optional(types.string, ''),
	phoneNumber: types.optional(types.string, ''),
	email: types.optional(types.string, ''),
	totalAmount: types.optional(types.number, 0),
	paymentType: types.optional(types.string, ''),
	status: types.optional(types.string, ''),
	orderNumber: types.maybeNull(types.number),
	orderItems: types.optional(types.array(OrderItem), []),
	shippingAddress: types.maybeNull(ShippingAddress),
	createdAt: types.optional(types.string, ''),
});

export default Order;
