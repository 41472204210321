import React from 'react';
import { useRouteError } from 'react-router-dom';
import { ErrorPage } from '../../../screens/ErrorPage';

function ErrorBoundary() {
	const error = useRouteError();
	console.log(error);
	return <ErrorPage />;
}

export default ErrorBoundary;
