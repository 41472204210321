/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { PageHeading } from '../../components/shared/PageHeading';
import { NavigateBackButton } from '../../components/shared/NavigateBackButton';
import { useStores } from '../../stores/context';
import PhotosCarousel from '../ProductPage/components/PhotosCarousel';
import './index.scss';

export const AboutUsPage = observer(() => {
	const {
		shopsStore: { currentShop },
	} = useStores();
	const { t } = useTranslation();

	return (
		<div className="about-us-page">
			<div className="about-us-page__header">
				<PageHeading headingText={t('about-us-page.title')} />
				<NavigateBackButton />
			</div>
			<div className="about-us-page__content">
				{currentShop?.images?.length > 0 && (
					<div className="about-us-page__images">
						<PhotosCarousel images={currentShop.images || []} />
					</div>
				)}

				<div className="about-us-page__description">
					<div
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(currentShop.description || ''),
						}}
					/>
				</div>
			</div>
		</div>
	);
});
