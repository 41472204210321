import { types } from 'mobx-state-tree';
import CustomerStoreActions from './actions';
import Customer, { DEFAULT_CUSTOMER } from './model';

const CustomerStore = types
	.model('CustomerStore', {
		currentCustomer: types.optional(Customer, DEFAULT_CUSTOMER),
		isLoggedIn: types.optional(types.boolean, false),
	})
	.actions(CustomerStoreActions);

export default CustomerStore;
