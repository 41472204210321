import { useEffect } from 'react';
import { useNavigationType, useLocation } from 'react-router-dom';

const getNavigationHistory = () => {
	return JSON.parse(sessionStorage.getItem('navigationHistory') || '[]');
};

function updateHistoryAndReturnLastTwo(history, location) {
	const index = history.indexOf(location);
	if (index > -1) {
		history.splice(index, 1);
	}

	history.push(location);

	if (history.length > 2) {
		return history.slice(-2);
	}

	return history;
}

const updateHistory = (location) => {
	const currentHistory = JSON.parse(
		sessionStorage.getItem('navigationHistory') || '[]',
	);
	const updatedHistory = updateHistoryAndReturnLastTwo(
		currentHistory,
		location,
	);

	sessionStorage.setItem('navigationHistory', JSON.stringify(updatedHistory));
};

function RouteChangeListener() {
	const location = useLocation();
	const navigationType = useNavigationType();

	useEffect(() => {
		if (navigationType !== 'POP') {
			updateHistory(location.pathname);
		}
	}, [location, navigationType]);

	return null;
}

export { getNavigationHistory, RouteChangeListener };
