import { flow, getEnv } from 'mobx-state-tree';

const CartStoreActions = (self) => ({
	toggleLoading: (value) => {
		self.loading = value;
	},
	getByCustomer: flow(function* getByCustomer(shopId, customerId, limit) {
		const { api } = getEnv(self);
		try {
			self.toggleLoading(true);
			const { data } = yield api.orders.getByCustomer(
				shopId,
				customerId,
				limit,
			);
			self.orders = data;
			self.toggleLoading(false);
		} catch (e) {
			self.toggleLoading(false);
			console.error(e);
		}
	}),
});

export default CartStoreActions;
