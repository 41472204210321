import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useStores } from '../../stores/context';
import './index.scss';

function AuthRedirectPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const { customerStore, shopsStore } = useStores();

	useEffect(() => {
		const queryString = location.search.substring(1);
		const params = new URLSearchParams(queryString);
		const tgAuthResult = params.toString();

		if (tgAuthResult && shopsStore.currentShop.id) {
			const initData = decodeURIComponent(tgAuthResult);
			customerStore
				.telegramLogin({
					initData,
					shopId: shopsStore.currentShop.id,
				})
				.then(() => {
					navigate(`/s/${shopsStore.currentShop.telegramShopBotUsername}`);
				})
				.catch(() => {
					navigate('/error');
				});
		} else {
			navigate('/error');
		}
	}, [location.search, shopsStore.currentShop.id, navigate]);

	return (
		<Box className="auth-redirect">
			<Box className="auth-redirect__content">
				<Typography variant="h6" component="h2">
					{t('auth-redirect-page.redirecting')}...
				</Typography>
				<CircularProgress sx={{ mt: 2 }} />
			</Box>
		</Box>
	);
}

export default AuthRedirectPage;
