import { flow, getEnv, getParent } from 'mobx-state-tree';
import { CartProduct } from './models';

const CartStoreActions = (self) => ({
	toggleLoading: (value) => {
		self.loading = value;
	},
	getCartItemsByIds: flow(function* getCartItemsByIds() {
		const { api } = getEnv(self);
		try {
			self.toggleLoading(true);
			const { data } = yield api.products.getByIds(
				self.currentCart.cart.map((item) => item.id),
			);
			const filteredData = data.filter(
				({ quantity, stockControl }) => !stockControl || quantity !== 0,
			);
			self.products = filteredData.map(
				({ images, attributes, quantity: inStock, stockControl, ...rest }) => {
					const cartQuantity =
						self.currentCart.cart.find((item) => item.id === rest.id)
							?.quantity || 1;

					const sortedImages = images.length
						? [...images].sort((a, b) => a.id - b.id)
						: [
								{
									regularUrl: process.env.REACT_APP_DEFAULT_IMAGE_URL,
								},
						  ];
					const imageUrl =
						sortedImages?.[0]?.regularUrl || sortedImages?.[0]?.originalUrl;

					return {
						...CartProduct.create({
							...rest,
							stockControl,
							image: imageUrl,
						}),
						inStock,
						attributes: JSON.parse(JSON.stringify(attributes)),
						quantity:
							stockControl && cartQuantity > inStock ? inStock : cartQuantity,
					};
				},
			);
			self.updateCart(
				self.products.map((product) => ({
					id: product.id,
					quantity: product.quantity,
				})),
			);
			self.toggleLoading(false);
		} catch (e) {
			self.toggleLoading(false);
			console.error(e);
		}
	}),
	getCart: flow(function* getCart() {
		const { api } = getEnv(self);
		const { customerStore, shopsStore } = getParent(self);
		try {
			const { data } = yield api.carts.find({
				customerId: customerStore.currentCustomer.id,
				shopId: shopsStore.currentShop.id,
			});
			self.currentCart = data;
		} catch (e) {
			console.error(e);
		}
	}),
	updateCart: flow(function* updateCart(cart) {
		const { api } = getEnv(self);
		try {
			yield api.carts.update(self.currentCart.id, { cart });
			self.currentCart = { ...self.currentCart, cart };
		} catch (e) {
			console.error(e);
		}
	}),
	addToCart(productId) {
		self.updateCart([...self.currentCart.cart, { id: productId, quantity: 1 }]);
	},
	removeFromCart: flow(function* removeFromCart(productId) {
		yield self.updateCart(
			self.currentCart.cart.filter((product) => product.id !== productId),
		);
		if (self.currentCart.cart.length === 0) yield self.getCartItemsByIds();
	}),
	toggleForCart(productId) {
		if (self.currentCart.cart.find((product) => product.id === productId))
			self.removeFromCart(productId);
		else self.addToCart(productId);
	},
	clearCart: flow(function* clearCart() {
		yield self.updateCart([]);
		if (self.currentCart.cart.length === 0) yield self.getCartItemsByIds();
	}),
	updateQuantity(id, quantity) {
		self.updateCart(
			self.currentCart.cart.map((product) => {
				if (product.id === id) return { ...product, quantity };
				return product;
			}),
		);
		self.products = self.products.map((product) =>
			product.id === id ? { ...product, quantity } : product,
		);
	},
	checkIsProductInCart(productId) {
		return Boolean(
			self.currentCart.cart.find((product) => product.id === productId),
		);
	},
});

export default CartStoreActions;
