import {
	createBrowserRouter,
	RouterProvider,
	ScrollRestoration,
} from 'react-router-dom';
import DefaultLayout from '../components/layouts/default';
import { ProductsPage } from '../screens/ProductsPage';
import { CartPage } from '../screens/CartPage';
import { CategoriesPage } from '../screens/CategoriesPage';
import { ErrorPage } from '../screens/ErrorPage';
import { ProductPage } from '../screens/ProductPage';
import { CheckoutPage } from '../screens/CheckoutPage';
import { WishlistPage } from '../screens/WishlistPage';
import { AboutUsPage } from '../screens/AboutUsPage';
import { ShopAdressesPage } from '../screens/ShopAdressesPage';
import { OrdersPage } from '../screens/OrdersPage';
import ProtectedRoute from './ProtectedRoute';
import AuthRedirectPage from '../screens/AuthRedirectPage';
import { RouteChangeListener } from './RouteChangeListener';
import ErrorBoundary from '../components/common/ErrorBoundary';

const router = createBrowserRouter([
	{
		path: 's/:telegramBotUsername',
		element: <DefaultLayout />,
		errorElement: <ErrorBoundary />,
		children: [
			{ index: true, element: <ProductsPage /> },
			{ path: ':productId', element: <ProductPage /> },
			{ path: 'categories', element: <CategoriesPage /> },
			{ path: 'checkout', element: <CheckoutPage /> },
			{ path: 'wishlist', element: <WishlistPage /> },
			{ path: 'orders', element: <OrdersPage /> },
			{ path: 'about-us', element: <AboutUsPage /> },
			{ path: 'shop-adresses', element: <ShopAdressesPage /> },
			{ path: 'auth-redirect', element: <AuthRedirectPage /> },
			{ path: '*', element: <ErrorPage /> },
			{
				element: <ProtectedRoute />,
				children: [
					{ path: 'cart', element: <CartPage /> },
					{ path: 'wishlist', element: <WishlistPage /> },
					{ path: 'orders', element: <OrdersPage /> },
					{ path: 'checkout', element: <CheckoutPage /> },
				],
			},
		],
	},
	{ path: '*', element: <ErrorPage /> },
]);

export function RouterConfig() {
	return (
		<RouterProvider router={router}>
			<RouteChangeListener />
			<ScrollRestoration />
		</RouterProvider>
	);
}
