import { PropTypes } from 'prop-types';
import { variantsValueOrder } from '../../../../utils/constants';
import './index.scss';

export function ProductVariants({
	variants,
	title,
	selectedVariant,
	setSelectedVariant,
	activeOptions,
}) {
	const sortedVariants = variants.sort((a, b) => {
		const indexA = variantsValueOrder.indexOf(a.value.value.toUpperCase());
		const indexB = variantsValueOrder.indexOf(b.value.value.toUpperCase());

		const orderA = indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA;
		const orderB = indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB;

		return orderA - orderB;
	});

	return (
		<div className="variant-options">
			<p className="variant-options__label">
				<span>{title}</span>:&nbsp;
				{selectedVariant?.value?.value}
			</p>
			<div className="variant-options__wrapper">
				<ul className="variant-options__list">
					{sortedVariants.map((variant) => (
						<li className="variant-options__item" key={variant.value.value}>
							<button
								className={`variant-options__preview ${
									variant?.value?.value === selectedVariant?.value?.value &&
									'variant-options__preview--active'
								} ${
									!activeOptions.includes(variant.value.id) &&
									'variant-options__disabled'
								} ${
									variant.quantity === 0 && 'variant-options__strikethrough'
								}`}
								aria-label="Variant preview"
								type="button"
								onClick={() => setSelectedVariant(title, variant)}
							>
								<span>{variant.value.value}</span>
							</button>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

ProductVariants.propTypes = {
	variants: PropTypes.instanceOf(Array).isRequired,
	activeOptions: PropTypes.instanceOf(Array).isRequired,
	selectedVariant: PropTypes.instanceOf(Object).isRequired,
	title: PropTypes.string.isRequired,
	setSelectedVariant: PropTypes.func.isRequired,
};
