import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import './index.scss';

export function ProductTabs({ activeTab, setActiveTab }) {
	const { t } = useTranslation();

	const renderTabItem = (value) => {
		return (
			<li
				className={`tabs__item ${activeTab === value && 'tabs__item--active'}`}
			>
				<button
					className="tabs__link"
					aria-label={`${value} tab`}
					type="button"
					onClick={() => setActiveTab(value)}
				>
					{t(`tabs.${value}`)}
				</button>
			</li>
		);
	};

	return (
		<div className="tabs">
			<ul className="tabs__list">
				{renderTabItem('details')}
				{renderTabItem('characteristics')}
				{renderTabItem('images')}
			</ul>
		</div>
	);
}

ProductTabs.propTypes = {
	activeTab: PropTypes.string.isRequired,
	setActiveTab: PropTypes.func.isRequired,
};
