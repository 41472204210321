import { flow, getEnv, getParent } from 'mobx-state-tree';

const AttributesStoreActions = (self) => ({
	getAttributes: flow(function* getAttributes(categoryId) {
		const { api } = getEnv(self);
		const { shopsStore } = getParent(self);
		try {
			const { data } = categoryId
				? yield api.attributes.findByCategory(
						categoryId,
						shopsStore.currentShop.id,
				  )
				: yield api.attributes.find(shopsStore.currentShop.id);

			self.attributes = data;
		} catch (e) {
			console.error(e);
		}
	}),
});

export default AttributesStoreActions;
