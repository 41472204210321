import { types } from 'mobx-state-tree';
import Order from './models';
import OrdersStoreActions from './actions';

const OrdersStore = types
	.model('OrdersStore', {
		orders: types.optional(types.array(Order), []),
		loading: types.optional(types.boolean, false),
	})
	.actions(OrdersStoreActions);

export default OrdersStore;
