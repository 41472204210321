import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import { Link, useParams } from 'react-router-dom';
import loaderGif from '../../assets/gifs/loaderGif.gif';
import { PageHeading } from '../../components/shared/PageHeading';
import { NavigateBackButton } from '../../components/shared/NavigateBackButton';
import { WishlistTile } from './components/WishlistTile';
import { useStores } from '../../stores/context';
import './index.scss';

export const WishlistPage = observer(() => {
	const {
		wishlistStore: {
			currentWishlist: { wishlist, id },
		},
		productsStore: { getByIds, products, loading: isLoading },
		shopsStore: {
			currentShop: { currencySymbol },
		},
	} = useStores();
	const { t } = useTranslation();
	const { telegramBotUsername } = useParams();

	const ws = toJS(wishlist);

	useEffect(() => {
		getByIds(ws);
	}, [id]);

	const renderWishlistContent = () => {
		if (products.length)
			return toJS(products).map((product) => (
				<WishlistTile
					key={product.id}
					item={product}
					currencySymbol={currencySymbol}
				/>
			));

		return (
			<div className="wishlist-page__empty">
				<h4>{t('wishlist-page.empty')}</h4>
				<Link to={`/s/${telegramBotUsername}/`}>
					<p>{t('wishlist-page.to-products')}</p>
				</Link>
			</div>
		);
	};

	return (
		<div className="wishlist-page">
			<div className="wishlist-page__header">
				<PageHeading headingText={t('wishlist-page.title')} />
				<NavigateBackButton />
			</div>
			<div className="wishlist-page__content">
				{isLoading ? (
					<div className="image-loader">
						<img
							src={loaderGif}
							alt="Loading..."
							style={{
								width: '100px',
								height: '100px',
								marginTop: '100px',
								marginBottom: '200px',
							}}
						/>
					</div>
				) : (
					renderWishlistContent()
				)}
			</div>
		</div>
	);
});
