const AppStoreActions = (self) => ({
	setIsOverlayVisible: (value) => {
		self.isOverlayVisible = value;
	},
	toggleLoading: (value) => {
		self.loading = value;
	},
	setProductsSwitchState: (value) => {
		self.productsSwitchState = value;
	},
	setIsAuthModalOpen: (value) => {
		self.isAuthModalOpen = value;
	},
});

export default AppStoreActions;
