import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import loaderGif from '../../../assets/gifs/loaderGif.gif';
import './index.scss';

const defaultImage = process.env.REACT_APP_DEFAULT_IMAGE_URL;

function ImageLoader({ images, size }) {
	const [isLoading, setIsLoading] = useState(true);
	const [src, setSrc] = useState(defaultImage);

	useEffect(() => {
		const sortedImages = images.length
			? [...images].sort((a, b) => a.id - b.id)
			: [{ regularUrl: defaultImage }];

		const imageUrl =
			sortedImages?.[0]?.regularUrl || sortedImages?.[0]?.originalUrl;

		setSrc(imageUrl);
	}, [images]);

	function handleImageLoaded() {
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	}

	return (
		<div className="image-loader__wrapper">
			{isLoading && (
				<div className="image-loader">
					<img
						src={loaderGif}
						alt="Loading..."
						style={{ width: size, height: size }}
					/>
				</div>
			)}
			<img
				src={src}
				onLoad={handleImageLoaded}
				alt="img"
				className={isLoading ? 'image-hidden' : 'image-visible'}
			/>
		</div>
	);
}

ImageLoader.defaultProps = {
	size: '50px',
};

ImageLoader.propTypes = {
	images: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			regularUrl: PropTypes.string,
			originalUrl: PropTypes.string,
		}),
	).isRequired,
	size: PropTypes.string,
};

export default ImageLoader;
