import { flow, getEnv, getParent } from 'mobx-state-tree';

const WishlistStoreActions = (self) => ({
	getWishlist: flow(function* getWishlist() {
		const { api } = getEnv(self);
		const { customerStore, shopsStore } = getParent(self);
		try {
			const { data } = yield api.wishlists.find({
				customerId: customerStore.currentCustomer.id,
				shopId: shopsStore.currentShop.id,
			});
			self.currentWishlist = data;
		} catch (e) {
			console.error(e);
		}
	}),
	updateWishlist: flow(function* updateWishlist(wishlist) {
		const { api } = getEnv(self);
		try {
			yield api.wishlists.update(self.currentWishlist.id, { wishlist });
			self.currentWishlist = { ...self.currentWishlist, wishlist };
		} catch (e) {
			console.error(e);
		}
	}),
});

export default WishlistStoreActions;
