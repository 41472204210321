/* eslint-disable max-len */
import { PropTypes } from 'prop-types';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { categoryTitleFormater } from '../../../utils/helpers';
import { getNavigationHistory } from '../../../navigation/RouteChangeListener';
import { useStores } from '../../../stores/context';
import { ShareProductButton } from '../ShareProductButton';
import './index.scss';

export const Breadcrumbs = observer(({ category }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { telegramBotUsername } = useParams();

	const {
		productsStore: { activeVariant },
		shopsStore: { currentShop },
	} = useStores();

	function getLastTwoCategories(rootCategory) {
		const categories = [];
		let currentCategory = rootCategory;

		while (currentCategory) {
			categories.unshift(currentCategory);
			currentCategory = currentCategory.parent;
		}

		return categories.slice(-2);
	}

	function handleNavigation(destinationCategory) {
		const history = getNavigationHistory();
		if (destinationCategory) {
			navigate(`/s/${telegramBotUsername}/`, {
				state: toJS(destinationCategory),
			});
		} else if (history.length === 1) {
			navigate(`/s/${telegramBotUsername}/`);
		} else {
			navigate(-1);
		}
	}

	const lastTwoCategories = getLastTwoCategories(category);

	return (
		<div className="breadcrumbs">
			<ul className="breadcrumbs__list">
				{lastTwoCategories.length > 1 && (
					<li className="breadcrumbs__item">
						<Link
							to={`/s/${telegramBotUsername}/categories`}
							className="breadcrumbs__link"
						>
							<span>...</span>
						</Link>
					</li>
				)}
				{lastTwoCategories.length > 1 &&
					lastTwoCategories.map((item, index) => (
						<li className="breadcrumbs__item" key={item.title}>
							{index !== 0 ? (
								<button
									className="breadcrumbs__link"
									type="button"
									onClick={() => handleNavigation(item)}
								>
									<KeyboardArrowRightRoundedIcon className="breadcrumbs__icon breadcrumbs__icon--right" />
									<span>{categoryTitleFormater(item?.title, t)}</span>
								</button>
							) : (
								<Link
									className="breadcrumbs__link"
									to={`/s/${telegramBotUsername}/categories`}
									state={{ activeCategoryId: item.id }}
								>
									<KeyboardArrowRightRoundedIcon className="breadcrumbs__icon breadcrumbs__icon--right" />
									<span>{categoryTitleFormater(item?.title, t)}</span>
								</Link>
							)}
						</li>
					))}
				{(lastTwoCategories.length === 1 || lastTwoCategories.length === 0) && (
					<li className="breadcrumbs__item">
						<button
							className="breadcrumbs__link"
							type="button"
							onClick={() => handleNavigation(category)}
						>
							<KeyboardArrowLeftRoundedIcon className="breadcrumbs__icon breadcrumbs__icon--left" />
							<span>
								{categoryTitleFormater(category?.title, t) ||
									t('breadcrumbs.back-button')}
							</span>
						</button>
					</li>
				)}
			</ul>
			{!!activeVariant.id && (
				<ShareProductButton shop={currentShop} product={activeVariant} />
			)}
		</div>
	);
});

Breadcrumbs.defaultProps = {
	category: {},
};

Breadcrumbs.propTypes = {
	category: PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string,
	}),
};
