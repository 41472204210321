import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { PageHeading } from '../../components/shared/PageHeading';
import { NavigateBackButton } from '../../components/shared/NavigateBackButton';
import { useStores } from '../../stores/context';
import { categoryTitleFormater } from '../../utils/helpers';
import './index.scss';

export const CategoriesPage = observer(() => {
	const [activeCategory, setActiveCategory] = useState(null);
	const [subcategories, setSubcategories] = useState([]);
	const { telegramBotUsername } = useParams();

	const {
		categoriesStore: { categories },
	} = useStores();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	const rootCategories = categories.filter((item) => !item.parentId);

	const handleSetActiveCategory = (categoryId) => {
		const category = categories.find((item) => item.id === categoryId);

		if (category.subcategories.length === 0) {
			navigate(`/s/${telegramBotUsername}/`, {
				state: { ...toJS(category) },
			});
			return;
		}

		setActiveCategory(category);
		setSubcategories(category.subcategories);
	};

	const handleGoToRoot = () => {
		if (!activeCategory.parentId) {
			setSubcategories([]);
			setActiveCategory(null);
		} else {
			const category = categories.find(
				(item) => item.id === activeCategory.parentId,
			);
			setSubcategories(category.subcategories);
			setActiveCategory(category);
		}
	};

	useEffect(() => {
		if (location.state?.activeCategoryId) {
			handleSetActiveCategory(location.state.activeCategoryId);
		}
	}, [location.state]);

	return (
		<div className="categories-page">
			{!activeCategory && (
				<div className="categories-page__header">
					<PageHeading headingText={t('categories-page.title')} />
					<NavigateBackButton
						handler={() => navigate(`/s/${telegramBotUsername}/`)}
					/>
				</div>
			)}
			{activeCategory && (
				<div className="categories-page__header">
					<Link
						className="active-category"
						to={`/s/${telegramBotUsername}/`}
						state={toJS(activeCategory)}
					>
						<span className="active-category__text">
							{t('categories-page.active-category-text')}
						</span>
						<span className="active-category__link">
							{categoryTitleFormater(activeCategory?.title, t).toLowerCase()}
						</span>
					</Link>
					<NavigateBackButton handler={handleGoToRoot} />
				</div>
			)}
			<div className="categories-page__content">
				<ul className="menu">
					{subcategories.length > 0
						? toJS(subcategories).map((category) => (
								<li className="menu__item" key={category.id}>
									<button
										className="menu__button"
										type="button"
										onClick={() => {
											handleSetActiveCategory(category.id);
										}}
									>
										<div className="menu__link">
											<span className="menu__link-title">
												{categoryTitleFormater(category.title, t)}
											</span>
											<KeyboardArrowRightRoundedIcon />
										</div>
									</button>
								</li>
						  ))
						: toJS(rootCategories).map((category) => (
								<li className="menu__item" key={category.id}>
									<button
										className="menu__button"
										type="button"
										onClick={() => {
											handleSetActiveCategory(category.id);
										}}
									>
										<div className="menu__link">
											<span className="menu__link-title">
												{categoryTitleFormater(category.title, t)}
											</span>
											<KeyboardArrowRightRoundedIcon />
										</div>
									</button>
								</li>
						  ))}
				</ul>
			</div>
		</div>
	);
});
