import PropTypes from 'prop-types';
import logoEN from '../../../assets/logos/powered-by-en.svg';
import logoUA from '../../../assets/logos/powered-by-ua.svg';
import './index.scss';

function PoweredByIcon({ en }) {
	return (
		<div className="powered-by-container">
			<a
				href={process.env.REACT_APP_LANDING_URL}
				target="_blank"
				rel="noreferrer"
				className="powered-by-link"
			>
				<img
					src={en ? logoEN : logoUA}
					alt="Powered by Teleshop"
					className="powered-by-logo"
				/>
			</a>
		</div>
	);
}

PoweredByIcon.propTypes = {
	en: PropTypes.bool.isRequired,
};

export default PoweredByIcon;
