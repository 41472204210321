import { types } from 'mobx-state-tree';
import Category from '../categoriesStore/model';
import ProductAttribute from '../pureModels/attribute-model';
import { ImageModel } from '../sharedModels';

const Variant = types.model('Variant', {
	id: types.maybeNull(types.identifierNumber),
	sku: types.optional(types.string, ''),
	title: types.optional(types.string, ''),
	description: types.optional(types.string, ''),
	price: types.optional(types.number, 0),
	discountPrice: types.maybeNull(types.number),
	quantity: types.optional(types.number, 0),
	images: types.optional(types.array(ImageModel), []),
	attributes: types.maybeNull(types.array(ProductAttribute)),
	rawAttributes: types.maybeNull(types.array(types.array(types.string))),
	sellStatus: types.optional(types.string, ''),
	sellStatusOverride: types.optional(types.boolean, false),
	variantParentId: types.maybeNull(types.number),
});

const Product = types.model('Product', {
	id: types.maybeNull(types.identifierNumber),
	sku: types.optional(types.string, ''),
	title: types.optional(types.string, ''),
	description: types.optional(types.string, ''),
	price: types.optional(types.number, 0),
	discountPrice: types.maybeNull(types.number),
	quantity: types.optional(types.number, 0),
	images: types.optional(types.array(ImageModel), []),
	category: types.maybeNull(Category),
	variants: types.maybeNull(types.array(Variant)),
	attributes: types.maybeNull(types.array(ProductAttribute)),
	rawAttributes: types.maybeNull(types.array(types.array(types.string))),
	stockControl: types.optional(types.boolean, true),
	sellStatus: types.optional(types.string, ''),
	sellStatusOverride: types.optional(types.boolean, false),
	enableFormatTitle: types.optional(types.boolean, false),
	isCanFormatTitle: types.optional(types.boolean, false),
	variantParentId: types.maybeNull(types.number),
});

const Links = types.model('Link', {
	first: types.maybeNull(types.string),
	previous: types.maybeNull(types.string),
	next: types.maybeNull(types.string),
	last: types.maybeNull(types.string),
});

const Meta = types.model('Meta', {
	currentPage: types.maybeNull(types.number),
	itemCount: types.maybeNull(types.number),
	itemsPerPage: types.maybeNull(types.number),
	totalItems: types.maybeNull(types.number),
	totalPages: types.maybeNull(types.number),
	orderBy: types.optional(types.string, ''),
	title: types.optional(types.string, ''),
	price: types.optional(types.string, ''),
	priceRange: types.frozen({
		minPrice: types.maybeNull(types.number),
		maxPrice: types.maybeNull(types.number),
	}),
	sellStatus: types.optional(types.string, ''),
});

const SearchQuery = types.model('SearchQuery', {
	title: types.optional(types.string, ''),
	page: types.optional(types.number, 1),
	orderBy: types.optional(types.string, 'novelty'),
	sellStatus: types.optional(types.string, ''),
	discountOnly: types.optional(types.boolean, false),
	price: types.optional(types.string, ''),
	attributes: types.optional(types.map(types.array(types.number)), {}),
});

const SearchResult = types.model('SearchResult', {
	categories: types.optional(types.array(Category), []),
	items: types.optional(types.array(Product), []),
});

export { Product, Links, Meta, SearchQuery, SearchResult };
