import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import './index.scss';

export function ProductStatuses({
	sellStatus,
	sellStatusOverride,
	quantity,
	noLabel,
	stockControl,
}) {
	const { t } = useTranslation();

	const getAvaliableStatus = () => {
		if (!sellStatusOverride && stockControl && quantity < 4) {
			return (
				<p className="product-statuses__label status-label--yellow">
					<RestoreOutlinedIcon />
					{quantity === 1
						? t('product-statuses.last-item')
						: t('product-statuses.running-out')}
				</p>
			);
		}
		return (
			<p className="product-statuses__label status-label--green">
				<CheckCircleOutlineRoundedIcon />
				{t('product-statuses.available')}
			</p>
		);
	};

	return (
		<div className={`product-statuses ${noLabel && 'no-label'}`}>
			{sellStatus === 'available' || sellStatusOverride ? (
				getAvaliableStatus()
			) : (
				<p className="product-statuses__label status-label--gray">
					<RemoveCircleOutlineRoundedIcon />
					{t('product-statuses.not-available')}
				</p>
			)}
		</div>
	);
}

ProductStatuses.defaultProps = {
	noLabel: true,
	sellStatusOverride: false,
};

ProductStatuses.propTypes = {
	sellStatus: PropTypes.string.isRequired,
	sellStatusOverride: PropTypes.bool,
	quantity: PropTypes.number.isRequired,
	stockControl: PropTypes.bool.isRequired,
	noLabel: PropTypes.bool,
};
