import { types } from 'mobx-state-tree';
import CheckoutStoreActions from './actions';
import { UserInfo } from './models';

const CheckoutStore = types
	.model('CheckoutStore', {
		userInfo: types.optional(UserInfo, {
			lastName: '',
			firstName: '',
			patronymic: '',
			phone: '',
			email: '',
		}),
		recipientInfo: types.optional(UserInfo, {
			lastName: '',
			firstName: '',
			patronymic: '',
			phone: '',
			email: '',
		}),
		amIRecipient: types.optional(types.boolean, false),
		comment: types.optional(types.string, ''),
	})
	.actions(CheckoutStoreActions);

export default CheckoutStore;
