import { types } from 'mobx-state-tree';
import { configure } from 'mobx';
import AppStore from './appStore/store';
import CartStore from './cartStore/store';
import ProductsStore from './productsStore/store';
import CategoriesStore from './categoriesStore/store';
import ShopsStore from './shopsStore/store';
import CheckoutStore from './checkoutStore/store';
import NovaPoshtaStore from './novaPoshtaStore/store';
import CustomerStore from './customerStore/store';
import WishlistStore from './wishlistStore/store';
import OrdersStore from './ordersStore/store';
import AttributesStore from './attributesStore/store';
import api, { setupInterceptors } from '../api';

configure({});

const RootStore = types.model('RootStore', {
	appStore: types.optional(AppStore, () => AppStore.create({})),
	productsStore: types.optional(ProductsStore, () => ProductsStore.create({})),
	cartStore: types.optional(CartStore, () => CartStore.create({})),
	wishlistStore: types.optional(WishlistStore, () => WishlistStore.create({})),
	ordersStore: types.optional(OrdersStore, () => OrdersStore.create({})),
	shopsStore: types.optional(ShopsStore, () => ShopsStore.create({})),
	customerStore: types.optional(CustomerStore, () => CustomerStore.create({})),
	checkoutStore: types.optional(CheckoutStore, () => CheckoutStore.create({})),
	attributesStore: types.optional(AttributesStore, () =>
		AttributesStore.create({}),
	),
	categoriesStore: types.optional(CategoriesStore, () =>
		CategoriesStore.create({}),
	),
	novaPoshtaStore: types.optional(NovaPoshtaStore, () =>
		NovaPoshtaStore.create({}),
	),
});

const RootStoreInstance = RootStore.create({}, { api });

setupInterceptors(RootStoreInstance);

export default RootStoreInstance;
