import { types } from 'mobx-state-tree';
import { Attribute } from '../pureModels/attribute-model';
import AttributesStoreActions from './actions';

const AttributesStore = types
	.model('AttributesStore', {
		attributes: types.optional(types.array(Attribute), []),
	})
	.actions(AttributesStoreActions);

export default AttributesStore;
