/* eslint-disable react/no-array-index-key */
import { memo } from 'react';
import { PropTypes } from 'prop-types';
import Carousel from 'react-material-ui-carousel';
import './index.scss';

const PhotosCarousel = memo(({ images }) => {
	const sortedImages = images.length
		? [...images].sort((a, b) => a.id - b.id)
		: [{ regularUrl: process.env.REACT_APP_DEFAULT_IMAGE_URL }];

	return (
		<div className="carousel-images">
			<Carousel
				navButtonsProps={{
					style: {
						backgroundColor: 'var(--global)',
						opacity: 0.7,
					},
				}}
				autoPlay={false}
				navButtonsAlwaysVisible
				swipe={false}
			>
				{sortedImages.map((photo, index) => (
					<div className="carousel-images__wrapper" key={index}>
						<img
							className="carousel-images__image"
							src={photo?.regularUrl || photo?.originalUrl}
							alt="img"
						/>
					</div>
				))}
			</Carousel>
		</div>
	);
});

PhotosCarousel.displayName = 'PhotosCarousel';

PhotosCarousel.propTypes = {
	images: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				id: PropTypes.number,
				regularUrl: PropTypes.string,
				originalUrl: PropTypes.string,
			}),
		]),
	).isRequired,
};

export default PhotosCarousel;
