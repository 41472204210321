import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import { useStores } from '../../../stores/context';
import './index.scss';

export const ShopLogo = observer(() => {
	const {
		shopsStore: { currentShop },
	} = useStores();
	const { telegramBotUsername } = useParams();

	const avatar =
		currentShop?.avatar?.thumbnailUrl ||
		currentShop?.avatar?.regularUrl ||
		currentShop?.avatar?.originalUrl;

	return (
		<div className="shop-logo">
			<Link to={`/s/${telegramBotUsername}/`} className="shop-logo__wrapper">
				<div className="shop-logo__picture">
					{avatar ? (
						<img src={avatar} alt="avatar" />
					) : (
						<div className="shop-logo__skeleton">
							<span>{currentShop.title?.charAt(0).toUpperCase()}</span>
						</div>
					)}
				</div>
			</Link>
		</div>
	);
});
