import { PropTypes } from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { formatPrice, formatProductTitle } from '../../../../utils/helpers';
import { ProductStatuses } from '../../../../components/shared/ProductStatuses';
import { AddToWishlistButton } from '../../../../components/shared/AddToWishlistButton';
import ImageLoader from '../../../../components/shared/ImageWithLoader';
import './index.scss';

export function WishlistTile({ item, currencySymbol }) {
	const {
		id,
		title,
		images = [],
		price = 0,
		discountPrice = 0,
		sellStatus = 'unavailable',
		quantity = 0,
		stockControl = false,
		variantParentId = null,
	} = item;

	const { telegramBotUsername } = useParams();

	const isDiscount = Boolean(discountPrice);

	return (
		<Link
			to={`/s/${telegramBotUsername}/${id}`}
			state={{ variantParentId }}
			title={title}
			className="wishlist-tile"
		>
			<div className="wishlist-tile__picture">
				<ImageLoader images={images} />
			</div>
			<div className="wishlist-tile__details">
				<p className="wishlist-tile__title">{formatProductTitle(item)}</p>
				<div className="wishlist-tile__price">
					<div className="wishlist-tile__price-value">
						{isDiscount && (
							<span className="wishlist-tile__price-old price-grey">
								{`${formatPrice(price)} ${currencySymbol}`}
							</span>
						)}
						<span
							className={`wishlist-tile__price-current ${
								isDiscount && 'price-red'
							}`}
						>
							{`${formatPrice(discountPrice || price)} ${currencySymbol}`}
						</span>
					</div>
					<AddToWishlistButton productId={id} />
				</div>
				<ProductStatuses
					sellStatus={sellStatus}
					stockControl={stockControl}
					quantity={quantity}
				/>
			</div>
		</Link>
	);
}

WishlistTile.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		images: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number,
				regularUrl: PropTypes.string,
				originalUrl: PropTypes.string,
			}),
		),
		price: PropTypes.number.isRequired,
		discountPrice: PropTypes.number,
		sellStatus: PropTypes.string.isRequired,
		quantity: PropTypes.number.isRequired,
		stockControl: PropTypes.bool.isRequired,
		variantParentId: PropTypes.number,
	}).isRequired,
	currencySymbol: PropTypes.string.isRequired,
};
