/* eslint-disable max-len */
import PropTypes from 'prop-types';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import { useTranslation } from 'react-i18next';
import { formatTelegramBotUsername } from '../../../utils/helpers';
import './index.scss';

export function ShareProductButton({ shop, product, width, height }) {
	const { t } = useTranslation();
	const handleShareProduct = async (event) => {
		event.preventDefault();

		const shareUrl = `https://t.me/${formatTelegramBotUsername(
			shop.telegramShopBotUsername,
		)}?start=op${product.id}`;

		if (navigator.share && product.title) {
			try {
				navigator.share({
					title: `🛍️ ${product.title.toUpperCase()} 🛍️`,
					text: `🛍️ ${product.title.toUpperCase()} 🛍️\n\n${t(
						'product-page.share-message',
					)}`,
					url: shareUrl,
				});
			} catch (error) {
				console.error('Error sharing', error);
			}
		} else {
			const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
				shareUrl,
			)}`;
			window.open(facebookUrl, '_blank');
		}
	};

	return (
		<button
			style={{ width, height }}
			className="share-button"
			aria-label="Share product"
			type="button"
			onClick={handleShareProduct}
		>
			<IosShareOutlinedIcon />
		</button>
	);
}

ShareProductButton.defaultProps = {
	width: '48px',
	height: '48px',
};

ShareProductButton.propTypes = {
	shop: PropTypes.instanceOf(Object).isRequired,
	product: PropTypes.instanceOf(Object).isRequired,
	width: PropTypes.string,
	height: PropTypes.string,
};
