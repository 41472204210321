/* eslint-disable react/no-danger */
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { formatPrice, formatProductTitle } from '../../utils/helpers';
import { useStores } from '../../stores/context';
import { PageHeading } from '../../components/shared/PageHeading';
import { AddToWishlistButton } from '../../components/shared/AddToWishlistButton';
import { ProductStatuses } from '../../components/shared/ProductStatuses';
import PhotosCarousel from './components/PhotosCarousel';
import { ProductColorOptions } from './components/ProductColorOptions';
import { ProductCharacteristics } from './components/ProductCharacteristics';
import { ProductPhotos } from './components/ProductPhotos';
import { ProductTabs } from './components/ProductTabs';
import { Breadcrumbs } from '../../components/shared/Breadcrumbs';
import loaderGif from '../../assets/gifs/loaderGif.gif';
import { ProductVariants } from './components/ProductVariants';
import { ShareTgProductButton } from '../../components/shared/ShareTgProductButton';
import './index.scss';

const extractNumber = (str) => {
	const match = str.match(/[\d.,]+/);
	return match ? parseFloat(match[0].replace(',', '.')) : null;
};

const compareVariants = (a, b) => {
	const aValue = extractNumber(a.value.value);
	const bValue = extractNumber(b.value.value);

	if (aValue !== null && bValue !== null) {
		return aValue - bValue;
	}
	return a.value.value.localeCompare(b.value.value, undefined, {
		numeric: true,
		sensitivity: 'base',
	});
};

const getVariants = (product) => {
	if (!product.variants?.length) return null;
	const variants = {};
	const parentVariantAttributes = (product.attributes || []).filter(
		(item) => item?.attribute?.isVariant,
	);

	if (parentVariantAttributes.length) {
		parentVariantAttributes.forEach((variant) => {
			const { attribute } = variant;
			if (variants[attribute.name]?.values?.length) {
				variants[attribute.name] = {
					type: attribute.type,
					values: [
						...variants[attribute.name].values,
						{ ...variant, quantity: product.quantity },
					],
				};
			} else {
				variants[attribute.name] = {
					type: attribute.type,
					values: [{ ...variant, quantity: product.quantity }],
				};
			}
		});
	}

	product.variants.forEach((variant) => {
		const variantAttributes = (variant.attributes || []).filter(
			(item) =>
				item?.attribute?.isVariant &&
				Object.keys(variants).includes(item?.attribute?.name),
		);
		if (variantAttributes.length) {
			variantAttributes.forEach((variantAttr) => {
				const { attribute } = variantAttr;
				if (variants[attribute.name]?.values?.length) {
					variants[attribute.name] = {
						type: attribute.type,
						values: [
							...variants[attribute.name].values,
							{ ...variantAttr, quantity: variant.quantity || 0 },
						],
					};
				} else {
					variants[attribute.name] = {
						type: attribute.type,
						values: [{ ...variantAttr, quantity: variant.quantity || 0 }],
					};
				}
			});
		}
	});

	Object.keys(variants).forEach((key) => {
		variants[key].values.sort(compareVariants);
	});

	return variants;
};

const getProductIds = (selected, all) => {
	const selectedProductIds = {};

	Object.entries(selected).forEach(([key, value]) => {
		selectedProductIds[key] = (all[key]?.values ?? [])
			.filter((item) => item.value.id === value?.value?.id)
			.map((item) => item.productId);
	});
	return selectedProductIds;
};

const filterVariants = (selected, all) => {
	const selectedProductIds = getProductIds(selected, all);
	const filteredVariants = all;

	if (all && Object.keys(selectedProductIds).length) {
		Object.entries(all).forEach(([key, value]) => {
			if (selectedProductIds[key]) {
				Object.entries(selectedProductIds).forEach(([selectedKey, item]) => {
					if (selectedKey !== key) {
						filteredVariants[key] = selectedProductIds[selectedKey]
							? {
									...value,
									values: value.values.filter((val) =>
										item.includes(val.productId),
									),
							  }
							: value;
					}
				});
			} else {
				filteredVariants[key] = value;
			}
		});
	}

	return filteredVariants;
};

const foundActiveVariant = (selected, all, product) => {
	let active = { ...product };
	const selectedProductIds = getProductIds(selected, all);
	if (Object.keys(selectedProductIds).length) {
		const commonElement = Object.values(selectedProductIds).reduce(
			(accumulator, currentValue) =>
				accumulator.filter((value) => currentValue.includes(value)),
		)[0];

		if (product.id !== commonElement) {
			active = product.variants.find((item) => item.id === commonElement);
		}
	}

	return active;
};

const generateAttributePresets = (product) => {
	const presets = [];

	if (product.id) {
		const parent = { productId: product.id };
		product.attributes.forEach((attribute) => {
			parent[attribute.attribute.name] = attribute.value.value;
		});
		presets.push(parent);
		product.variants.forEach((p) => {
			const child = { productId: p.id };
			p.attributes.forEach((attribute) => {
				child[attribute.attribute.name] = attribute.value.value;
			});
			presets.push(child);
		});
	}

	return presets;
};

const createSearchTargetFromObject = (obj) => {
	const target = {};

	Object.keys(obj).forEach((a) => {
		if (obj[a].value && obj[a].value.value) {
			target[a] = obj[a].value.value;
		}
	});

	return target;
};

const uniqVariants = (values) => {
	if (!values?.length) return [];
	const uniqueValues = values.reduce((accumulator, currentValue) => {
		const existingObject = accumulator.find(
			(obj) => obj.value.id === currentValue.value.id,
		);
		if (!existingObject) {
			accumulator.push(currentValue);
		}
		return accumulator;
	}, []);
	return uniqueValues.sort(compareVariants);
};

export const ProductPage = observer(() => {
	const {
		productsStore,
		cartStore,
		shopsStore: { currentShop },
		appStore,
		customerStore,
	} = useStores();
	const { t } = useTranslation();
	const { pathname, state } = useLocation();
	const { telegramBotUsername } = useParams();

	const currentProductId = pathname.split('/').pop();
	const {
		loading: isLoading,
		findParentIfExist,
		currentProduct,
		resetCurrentProduct,
		activeVariant,
		setActiveVariant,
		resetActiveVariant,
	} = productsStore;

	const textRef = useRef(null);
	const [hasCheckedHeight, setHasCheckedHeight] = useState(false);
	const [collapsedProductAbout, setCollapsedProductAbout] = useState(false);

	const [variants, setVariants] = useState({});
	const [selectedVariant, setSelectedVariant] = useState({});
	const [attributePresets, setAttributePresets] = useState([]);

	const [activeTab, setActiveTab] = useState('details');
	const isDiscount = Boolean(activeVariant?.discountPrice);

	useEffect(() => {
		setVariants(getVariants(toJS(currentProduct)));
	}, [currentProduct]);

	const filteredVariants = useMemo(
		() => filterVariants(selectedVariant, { ...variants }),
		[selectedVariant, variants],
	);

	useEffect(() => {
		findParentIfExist(state?.variantParentId ?? currentProductId);

		return () => {
			resetCurrentProduct();
			resetActiveVariant();
		};
	}, [currentProductId, state]);

	useEffect(() => {
		if (currentProduct.id) {
			const presets = generateAttributePresets(toJS(currentProduct));
			setAttributePresets(presets);
		}
	}, [currentProduct, currentProduct.id, state, currentProductId]);

	useEffect(() => {
		if (currentProduct.id) {
			const res = state?.variantParentId
				? {
						...toJS(currentProduct),
						...toJS(currentProduct).variants.find(
							(item) => item.id === +currentProductId,
						),
				  }
				: toJS(currentProduct);

			setActiveVariant({ ...res });
		}
	}, [currentProduct, state, currentProductId]);

	useEffect(() => {
		if (currentProduct.id) {
			const res = {};
			const parentVariantAttributes = (
				toJS(currentProduct).variants.find(
					(item) => item.id === +currentProductId,
				)?.attributes ||
				toJS(currentProduct).attributes ||
				[]
			).filter((item) => item?.attribute?.isVariant);

			if (parentVariantAttributes.length) {
				parentVariantAttributes.forEach((variant) => {
					const { attribute } = variant;
					res[attribute.name] = variant;
				});
			}
			setSelectedVariant(res);
		}
	}, [currentProduct, state, currentProductId]);

	useEffect(() => {
		if (textRef.current && !hasCheckedHeight) {
			const height = textRef.current.offsetHeight;
			if (height > 200) {
				setCollapsedProductAbout(true);
				setHasCheckedHeight(true);
			}
		}
	}, [textRef?.current?.offsetHeight]);

	const handleIsProductInCart = useCallback(
		() => cartStore.checkIsProductInCart(activeVariant?.id),
		[toJS(cartStore.currentCart.cart)],
	);

	const handleAddToCart = (event) => {
		event.preventDefault();
		if (!customerStore.isLoggedIn) {
			appStore.setIsAuthModalOpen(true);
		} else {
			cartStore.toggleForCart(activeVariant.id);
		}
	};

	const handleSelectVariant = useCallback(
		(key, value) => {
			const product = toJS(currentProduct);

			const variant = {
				...selectedVariant,
				[key]: value,
			};

			if (attributePresets.length) {
				const searchTarget = createSearchTargetFromObject(variant);

				const targetItem = attributePresets.find((item) => {
					return Object.keys(searchTarget)
						.filter((field) => field !== 'productId')
						.every((field) => item[field] === searchTarget[field]);
				});

				if (!targetItem) {
					const foundItem = attributePresets.find(
						(item) => item[key] === value.value.value,
					);

					let attributes;
					if (foundItem.productId === product.id) {
						attributes = currentProduct.attributes;
					} else {
						attributes = currentProduct.variants.find(
							(child) => child.id === foundItem.productId,
						)?.attributes;
					}

					if (attributes) {
						Object.keys(variant).forEach((field) => {
							variant[field] = attributes.find(
								(attribute) => attribute.attribute.name === field,
							);
						});
					}
				}
			}

			setSelectedVariant(variant);
			setActiveVariant({
				...toJS(currentProduct),
				...foundActiveVariant(variant, variants, product),
			});
		},
		[selectedVariant, currentProduct, variants],
	);

	const getActiveTab = () => {
		switch (activeTab) {
			case 'characteristics':
				return (
					<ProductCharacteristics
						category={toJS(activeVariant.category)}
						attributes={toJS(activeVariant?.attributes)}
					/>
				);
			case 'images':
				return <ProductPhotos images={activeVariant?.images || []} />;
			default:
				return (
					<>
						{isLoading ? (
							<div className="carousel-skeleton" />
						) : (
							<PhotosCarousel images={activeVariant?.images || []} />
						)}
						{Boolean(variants) && (
							<div className="product-variants">
								{Object.entries(variants).map(([title, variant]) =>
									variant.type === 'color' ? (
										<ProductColorOptions
											key={title}
											title={title}
											activeOptions={filteredVariants[title].values.map(
												(item) => item.value.id,
											)}
											colors={uniqVariants(variant.values)}
											selectedColor={selectedVariant[title] || {}}
											setSelectedColor={handleSelectVariant}
										/>
									) : (
										<ProductVariants
											key={title}
											variants={uniqVariants(variant.values).map((v) => ({
												...v,
												quantity: v.quantity !== undefined ? v.quantity : 0,
											}))}
											title={title}
											activeOptions={filteredVariants[title].values.map(
												(item) => item.value.id,
											)}
											selectedVariant={selectedVariant[title] || {}}
											setSelectedVariant={handleSelectVariant}
										/>
									),
								)}
							</div>
						)}
						<ProductStatuses
							quantity={activeVariant.quantity}
							sellStatus={activeVariant.sellStatus}
							stockControl={activeVariant.stockControl}
							noLabel={false}
						/>
						<div className="product-trade">
							<div className="product-trade__inner">
								{isDiscount && (
									<p className="product-trade__discount">
										{formatPrice(activeVariant.price)}
										<span>{currentShop.currencySymbol}</span>
									</p>
								)}
								<p
									className={`product-trade__price ${
										isDiscount && 'product-trade__price--red'
									}`}
								>
									{formatPrice(
										activeVariant?.discountPrice || activeVariant.price,
									)}
									<span>{currentShop.currencySymbol}</span>
								</p>
								<div className="product-trade__buttons">
									<AddToWishlistButton productId={activeVariant?.id} />
									<ShareTgProductButton
										shop={currentShop}
										product={activeVariant}
									/>
								</div>
							</div>
							{handleIsProductInCart() ? (
								<Link
									to={`/s/${telegramBotUsername}/cart`}
									className="product-trade__redirect-to-cart"
								>
									<CheckCircleOutlineRoundedIcon />
									<span>{t('product-page.item-state')}</span>
								</Link>
							) : (
								<button
									className="product-trade__buy-button"
									aria-label="Buy"
									type="button"
									disabled={activeVariant.sellStatus === 'unavailable'}
									onClick={handleAddToCart}
								>
									<ShoppingCartOutlinedIcon />
									<span>{t('product-page.buy-button')}</span>
								</button>
							)}
						</div>
						<div className="product-about">
							{Boolean(activeVariant.description) &&
								activeVariant.description !== '-' && (
									<div className="product-about__description">
										<h3 className="product-about__heading">
											{t('product-page.description')}
											<span>:&nbsp;{formatProductTitle(activeVariant)}</span>
										</h3>
										<p
											ref={textRef}
											className={`product-about__description-content ${
												collapsedProductAbout ? 'state_collapsed' : ''
											}`}
										>
											<span
												dangerouslySetInnerHTML={{
													__html: DOMPurify.sanitize(activeVariant.description),
												}}
											/>
										</p>
										{collapsedProductAbout && (
											<button
												className="product-about__collapse-button"
												type="button"
												onClick={() => setCollapsedProductAbout(false)}
											>
												{t('product-page.collapse-button')}
											</button>
										)}
									</div>
								)}
							<div className="product-about__characteristics">
								<h3 className="product-about__heading">
									{t('product-page.characteristics')}
									<span>:&nbsp;{formatProductTitle(activeVariant)}</span>
								</h3>
								<ProductCharacteristics
									category={toJS(activeVariant.category)}
									attributes={toJS(activeVariant?.attributes)}
								/>
							</div>
						</div>
					</>
				);
		}
	};

	return (
		<div className="product-page">
			{isLoading ? (
				<div className="image-loader">
					<img
						src={loaderGif}
						alt="Loading..."
						style={{
							width: '100px',
							height: '100px',
							marginTop: '100px',
							marginBottom: '200px',
						}}
					/>
				</div>
			) : (
				<>
					<Breadcrumbs
						category={state?.fromCategory ? activeVariant.category : null}
					/>
					<PageHeading
						headingText={formatProductTitle(activeVariant)}
						margin="0 0 16px 0"
					/>
					<div className="product-page__rating">
						<p className="product-page__code">
							<span>{t('product-page.code')}:</span>&nbsp;{activeVariant?.sku}
						</p>
					</div>
					<ProductTabs activeTab={activeTab} setActiveTab={setActiveTab} />
					{getActiveTab()}
				</>
			)}
		</div>
	);
});
