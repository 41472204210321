import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import './index.scss';

export function PhoneNumberWrapper({ phoneNumber }) {
	const { t } = useTranslation();

	const [text, setText] = useState(phoneNumber);
	const [showAlternateText, setShowAlternateText] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			setShowAlternateText(!showAlternateText);
		}, 2000);

		return () => clearInterval(interval);
	}, [showAlternateText]);

	const handleClick = (event) => {
		const textForCopy = event.target.innerText;
		navigator.clipboard.writeText(textForCopy);

		setText(t('copied-to-buffer'));
		setTimeout(() => {
			setText(phoneNumber);
		}, 2000);
	};

	return (
		<button type="button" onClick={handleClick} className="phoneNumber">
			{text}
		</button>
	);
}

PhoneNumberWrapper.propTypes = {
	phoneNumber: PropTypes.string.isRequired,
};
