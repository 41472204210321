/* eslint-disable react/no-array-index-key */
import { PropTypes } from 'prop-types';
import './index.scss';

export function ProductPhotos({ images }) {
	const sortedImages = images.length
		? [...images].sort((a, b) => a.id - b.id)
		: [{ regularUrl: process.env.REACT_APP_DEFAULT_IMAGE_URL }];

	return (
		<div className="products-images">
			<ul className="products-images__list">
				{sortedImages.map((photo, index) => (
					<li className="products-images__item" key={index}>
						<img src={photo?.regularUrl || photo?.originalUrl} alt="img" />
					</li>
				))}
			</ul>
		</div>
	);
}

ProductPhotos.propTypes = {
	images: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				id: PropTypes.number,
				regularUrl: PropTypes.string,
				originalUrl: PropTypes.string,
			}),
		]),
	).isRequired,
};
