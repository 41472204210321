import { PropTypes } from 'prop-types';
import { IconButton } from '@mui/material';
import './index.scss';

export function BlockTitle({ number, title, actionIcon, action }) {
	return (
		<div className="block-title">
			<div className="block-title__info">
				<div className="block-title__number">{number}</div>
				<h2 className="block-title__title">{title}</h2>
			</div>
			{action ? (
				<IconButton onClick={action} className="block-title__action-button">
					{actionIcon}
				</IconButton>
			) : null}
		</div>
	);
}

BlockTitle.defaultProps = {
	actionIcon: null,
	action: null,
};

BlockTitle.propTypes = {
	number: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	actionIcon: PropTypes.element,
	action: PropTypes.func,
};
