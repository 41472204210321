import { types } from 'mobx-state-tree';
import AppStoreActions from './actions';

const AppStore = types
	.model('AppStore', {
		isOverlayVisible: types.optional(types.boolean, false),
		loading: types.optional(types.boolean, false),
		productsSwitchState: types.optional(types.number, 6),
		isAuthModalOpen: types.optional(types.boolean, false),
	})
	.actions(AppStoreActions);

export default AppStore;
