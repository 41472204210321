import { PropTypes } from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { formatPrice, formatProductTitle } from '../../../../utils/helpers';
import { ProductStatuses } from '../../../../components/shared/ProductStatuses';
import ImageLoader from '../../../../components/shared/ImageWithLoader';
import { AddToWishlistButton } from '../../../../components/shared/AddToWishlistButton';
import { AddToCartButton } from './components/AddToCartButton';
import './index.scss';

export function ProductTile({
	item,
	tileType,
	isProductInCart,
	fromCategory,
	shopFormatTitle,
	currencySymbol,
}) {
	const {
		id,
		title,
		images = [],
		enableFormatTitle = true,
		isCanFormatTitle = false,
		price = 0,
		discountPrice = 0,
		sellStatus = 'unavailable',
		sellStatusOverride = false,
		quantity = 0,
		stockControl = false,
		variantParentId = null,
	} = item;

	const { telegramBotUsername } = useParams();

	return (
		<Link
			to={`/s/${telegramBotUsername}/${id}`}
			title={title}
			state={{ fromCategory, variantParentId }}
			className={`product-tile ${tileType}`}
		>
			<div className="product-tile__picture">
				<ImageLoader images={images} />
			</div>
			<div className="product-tile__details">
				<p className="product-tile__title">
					{shopFormatTitle && enableFormatTitle && isCanFormatTitle
						? formatProductTitle(item)
						: title}
				</p>
				<div className="product-tile__controls">
					<div className="product-tile__price-value">
						{Boolean(discountPrice) && (
							<span className="product-tile__price-old price-grey">
								{`${formatPrice(price)} ${currencySymbol}`}
							</span>
						)}
						<span
							className={`product-tile__price-current ${
								Boolean(discountPrice) && 'price-red'
							}`}
						>
							{`${formatPrice(discountPrice || price)} ${currencySymbol}`}
						</span>
					</div>
					<AddToWishlistButton productId={id} width="32px" height="32px" />
				</div>
				<div className="product-tile__controls" style={{ marginTop: '0px' }}>
					<ProductStatuses
						sellStatus={sellStatus}
						sellStatusOverride={sellStatusOverride}
						quantity={quantity}
						stockControl={stockControl}
					/>
					<AddToCartButton
						productId={id}
						isProductInCart={isProductInCart}
						disabled={sellStatus === 'unavailable' || sellStatusOverride}
					/>
				</div>
			</div>
		</Link>
	);
}

ProductTile.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		images: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number,
				regularUrl: PropTypes.string,
				originalUrl: PropTypes.string,
			}),
		),
		enableFormatTitle: PropTypes.bool,
		isCanFormatTitle: PropTypes.bool,
		price: PropTypes.number.isRequired,
		discountPrice: PropTypes.number,
		sellStatus: PropTypes.string.isRequired,
		sellStatusOverride: PropTypes.bool.isRequired,
		quantity: PropTypes.number.isRequired,
		stockControl: PropTypes.bool.isRequired,
		variantParentId: PropTypes.number,
	}).isRequired,
	tileType: PropTypes.string.isRequired,
	isProductInCart: PropTypes.bool.isRequired,
	fromCategory: PropTypes.bool.isRequired,
	shopFormatTitle: PropTypes.bool.isRequired,
	currencySymbol: PropTypes.string.isRequired,
};
