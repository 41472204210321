import PropTypes from 'prop-types';
import TelegramIcon from '@mui/icons-material/Telegram';
import { useTranslation } from 'react-i18next';
import { formatTelegramBotUsername } from '../../../utils/helpers';
import './index.scss';

export function ShareTgProductButton({ shop, product, width, height }) {
	const { t } = useTranslation();

	const handleShareProduct = (event) => {
		event.preventDefault();

		const encodedURL = encodeURIComponent(
			`https://t.me/${formatTelegramBotUsername(
				shop.telegramShopBotUsername,
			)}?start=op${product.id}`,
		);
		const encodedTitle = encodeURIComponent(
			`🛍️ ${product.title.toUpperCase()} 🛍️`,
		);
		const encodedDescription = encodeURIComponent(
			t('product-page.share-message'),
		);
		const encodedText = `${encodedTitle}%0A%0A${encodedDescription}`;
		const telegramUrl = `https://t.me/share/url?url=${encodedURL}&text=${encodedText}`;

		window.open(telegramUrl, '_blank');
	};

	return (
		<button
			style={{ width, height }}
			className="share-tg-button"
			aria-label="Share product in Telegram"
			type="button"
			onClick={handleShareProduct}
		>
			<TelegramIcon />
		</button>
	);
}

ShareTgProductButton.defaultProps = {
	width: '48px',
	height: '48px',
};

ShareTgProductButton.propTypes = {
	shop: PropTypes.instanceOf(Object).isRequired,
	product: PropTypes.instanceOf(Object).isRequired,
	width: PropTypes.string,
	height: PropTypes.string,
};
