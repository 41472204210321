import React, { useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from '../stores/context';

const ProtectedRoute = observer(() => {
	const { customerStore, appStore } = useStores();
	const { telegramBotUsername } = useParams();

	useEffect(() => {
		if (!customerStore.isLoggedIn) {
			appStore.setIsAuthModalOpen(true);
		}
	}, [customerStore.currentCustomer.id, appStore]);

	if (!customerStore.isLoggedIn) {
		return <Navigate to={`/s/${telegramBotUsername}`} replace />;
	}

	return <Outlet />;
});

export default ProtectedRoute;
