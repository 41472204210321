import { types } from 'mobx-state-tree';
import ShopsStoreActions from './actions';
import { Shop } from './model';

const ShopsStore = types
	.model('ShopsStore', {
		currentShop: types.optional(Shop, { id: 0 }),
	})
	.actions(ShopsStoreActions);

export default ShopsStore;
