import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../stores/context';
import { sortArrayBy } from '../../../../../../utils/helpers';
import './index.scss';

export const FiltersMenu = observer(({ setOpenedFilter }) => {
	const { productsStore, attributesStore } = useStores();
	const { t } = useTranslation();

	const handleOpenFilterTab = (value) => setOpenedFilter(value);

	const otherFilters = sortArrayBy(toJS(attributesStore.attributes), 'name');

	const getFilterValue = (value) => {
		const filterValue = productsStore.searchQuery[value];

		const filter = otherFilters.find((item) => item.name === value);

		if (filter) {
			let attributeValueIds;

			toJS(productsStore.searchQuery.attributes).forEach((ids, id) => {
				if (id === filter.id.toString()) attributeValueIds = ids;
			});

			if (attributeValueIds?.length) {
				const values = toJS(filter.values).filter(({ id }) =>
					attributeValueIds.includes(id),
				);

				return values.map((item) => item.value.split(',#')[0]).join(', ');
			}
		}

		if (filterValue) {
			switch (filterValue) {
				case 'available':
					return t('status-filter.status-available');
				case 'unavailable':
					return t('status-filter.status-unavailable');
				default:
					return filterValue;
			}
		} else return t('filters-menu.all');
	};

	const renderFilterItem = (value, notVariable) => {
		return (
			<li className="filters-menu__item" key={`filter-item-${value}`}>
				<button
					className="filters-menu__link"
					aria-label="Open filter"
					type="button"
					onClick={() => handleOpenFilterTab(value)}
				>
					<span className="filters-menu__title">
						{notVariable ? value : t(`filters-menu.${value}`)}
					</span>
					<span className="filters-menu__values">{getFilterValue(value)}</span>
					<KeyboardArrowRightRoundedIcon />
				</button>
			</li>
		);
	};

	return (
		<ul className="filters-menu__list">
			{renderFilterItem('price')}
			{renderFilterItem('sellStatus')}
			{renderFilterItem('discountOnly')}
			{otherFilters.map((filter) => renderFilterItem(filter.name, true))}
		</ul>
	);
});

FiltersMenu.propTypes = {
	setOpenedFilter: PropTypes.func.isRequired,
};
