import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useStores } from '../../../../../../stores/context';
import './index.scss';

export const AddToCartButton = observer(
	({ productId, isProductInCart, disabled }) => {
		const {
			customerStore,
			appStore,
			cartStore: { toggleForCart },
		} = useStores();

		const handleAddToCart = (event) => {
			event.preventDefault();
			if (!customerStore.isLoggedIn) {
				appStore.setIsAuthModalOpen(true);
			} else {
				toggleForCart(productId);
			}
		};

		return (
			<button
				className={`add-to-cart-button ${disabled ? 'disabled' : ''}`}
				aria-label="Purchase product"
				type="button"
				disabled={disabled}
				onClick={handleAddToCart}
			>
				{isProductInCart ? (
					<CheckCircleOutlineRoundedIcon />
				) : (
					<ShoppingCartOutlinedIcon />
				)}
			</button>
		);
	},
);

AddToCartButton.propTypes = {
	productId: PropTypes.number.isRequired,
	isProductInCart: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired,
};
