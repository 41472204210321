import { types } from 'mobx-state-tree';
import NovaPoshtaStoreActions from './actions';
import { Settlement, Street, Warehouse } from './models';

const NovaPoshtaStore = types
	.model('NovaPoshtaStore', {
		settlements: types.array(Settlement, []),
		warehouses: types.array(Warehouse, []),
		streets: types.array(Street, []),
		loading: types.optional(types.boolean, false),
	})
	.actions(NovaPoshtaStoreActions);

export default NovaPoshtaStore;
