import { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import {
	Checkbox,
	FormControlLabel,
	InputLabel,
	TextField,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useStores } from '../../stores/context';
import { BlockTitle } from './components/BlockTitle';
import { PageHeading } from '../../components/shared/PageHeading';
import { ContactDetails } from './components/ContactDetails';
import { ProductsPreview } from './components/ProductsPreview';
import { Pickup } from './components/Pickup';
import { InTotal } from './components/InTotal';
import { Payments } from './components/Payments';
import {
	calculateTotalPrice,
	validateEmail,
	phoneValidationRules,
} from '../../utils/helpers';
import { Loader } from '../../components/common/Loader';
import { NavigateBackButton } from '../../components/shared/NavigateBackButton';
import './index.scss';

export const CheckoutPage = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { telegramBotUsername } = useParams();
	const {
		novaPoshtaStore,
		cartStore,
		shopsStore,
		checkoutStore: {
			userInfo,
			setUserInfo,
			recipientInfo,
			setRecipientInfo,
			amIRecipient,
			setAmIRecipient,
			comment,
			setComment,
		},
		customerStore: { currentCustomer },
		ordersStore: { orders, getByCustomer },
	} = useStores();

	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
	const [settlement, setSettlement] = useState({});
	const [loading, setLoading] = useState(false);
	const [deliveryType, setDeliveryType] = useState('selfPickup');
	const [destination, setDestination] = useState('');
	const [warehouse, setWarehouse] = useState({});
	const [shopLocation, setShopLocation] = useState({});
	const [errors, setErrors] = useState([]);
	const [userInfoErrors, setUserInfoErrors] = useState([]);
	const [address, setAddress] = useState({
		street: '',
		building: '',
		entrance: '',
		apartment: '',
	});
	const [paymentType, setPaymentType] = useState();
	const [paymentMethod, setPaymentMethod] = useState();
	const products = toJS(cartStore.products);
	const iAmRecipient = toJS(amIRecipient);
	const {
		paymentTypes,
		id: shopId,
		minOrderAmount,
		language,
	} = toJS(shopsStore.currentShop);

	const totalPrice = calculateTotalPrice(products);

	const validateUserInfo = (fieldName, value, countryCode) => {
		if (
			(fieldName === 'phone' &&
				phoneValidationRules[countryCode] &&
				phoneValidationRules[countryCode](value)) ||
			(fieldName === 'email' && value && !validateEmail(value)) ||
			(fieldName !== 'email' && !value)
		)
			setUserInfoErrors([...userInfoErrors, fieldName]);
		else setUserInfoErrors(userInfoErrors.filter((item) => item !== fieldName));
	};

	const validate = (fieldName, value, countryCode) => {
		if (
			(fieldName === 'phone' &&
				phoneValidationRules[countryCode] &&
				phoneValidationRules[countryCode](value)) ||
			(fieldName === 'email' && value && !validateEmail(value)) ||
			(fieldName !== 'email' && !value)
		)
			setErrors([...errors, fieldName]);
		else setErrors(errors.filter((item) => item !== fieldName));
	};

	const getError = (fieldName) => {
		if (fieldName === 'email') return t('checkout-page.errors.invalid-email');
		if (fieldName === 'phone') return t('checkout-page.errors.invalid-phone');
		return `${fieldName} ${t('checkout-page.errors.required')}`;
	};

	const handleUpdateSettlement = (value) => {
		setSettlement(value);
		setWarehouse({});
	};

	const handleClickCheckbox = () => {
		setIsCheckboxChecked(true);

		setTimeout(() => {
			setIsCheckboxChecked(false);
		}, 600);
	};

	const validateRequiredFields = (value, requiredFields) => {
		const missed = [];
		Object.keys(value).forEach((key) => {
			if (
				(requiredFields.includes(key) && !value[key]) ||
				(key === 'email' && value[key] && !validateEmail(value[key]))
			)
				missed.push(key);
		});
		return missed;
	};

	const focusToField = (name, value) => {
		const el = document.getElementsByName(name)[value];
		if (el) el.focus();
	};

	const validateData = (combined) => {
		const requiredFields = ['firstName', 'lastName', 'phone'];

		const userInfoErrs = validateRequiredFields(userInfo, requiredFields);
		if (userInfoErrs.length > 0) focusToField(userInfoErrs[0], 0);
		setUserInfoErrors(userInfoErrs);

		let errs = [];
		if (combined) errs = userInfoErrs;
		else errs = validateRequiredFields(recipientInfo, requiredFields);
		if (!combined && userInfoErrs.length === 0 && errs.length > 0)
			focusToField(errs[0], 1);
		setErrors(errs);

		return !(errs.length + userInfoErrs.length);
	};

	const handleCreateOrder = async () => {
		try {
			const valid = validateData(iAmRecipient);
			if (!valid || errors.length > 0 || userInfoErrors.length > 0) return;
			setLoading(true);
			const recipient = iAmRecipient ? userInfo : recipientInfo;
			const shippingAddress = {
				type: deliveryType,
				city: settlement.Present || '',
				npDepartment: warehouse.Description || '',
				...address,
				destination,
				shopLocation: shopLocation?.location
					? shopLocation.location.replace(/<br \/>/g, '')
					: '',
				fullName: `${recipient.lastName.trim()} ${recipient.firstName.trim()} ${recipient.patronymic.trim()}`,
				phoneNumber: recipient.phone,
				email: recipient.email.trim(),
			};

			const createdShippingAddress = await shopsStore.createShippingAddress(
				shippingAddress,
			);

			if (createdShippingAddress.id) {
				const { id: customerId, telegramUserId } = toJS(currentCustomer);
				const order = {
					customerId,
					shopId,
					comment,
					shippingAddressId: createdShippingAddress.id,
					fullName: `${userInfo.lastName.trim()} ${userInfo.firstName.trim()} ${userInfo.patronymic.trim()}`,
					phoneNumber: userInfo.phone,
					email: userInfo.email.trim(),
					paymentType,
					orderProducts: products.map((product) => ({
						id: product.id,
						quantity: product.quantity,
					})),
					telegramChatId: telegramUserId,
					paymentMethodId: Number(paymentMethod),
				};

				await shopsStore.createOrder(order);
				setLoading(false);

				if (window?.Telegram?.WebApp?.initData) {
					window.Telegram.WebApp.disableClosingConfirmation();
					window.Telegram.WebApp.close();
				} else {
					const telegramUrl = `https://t.me/${telegramBotUsername}`;
					window.open(telegramUrl, '_blank');
					navigate(`/s/${telegramBotUsername}/orders`);
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getUserInfoFromLastOrder = () => {
		const order = toJS(orders).at(0);

		const fullName = order.fullName.split(' ');
		setUserInfo({
			lastName: fullName[0],
			firstName: fullName[1],
			patronymic: fullName[2],
			phone: order.phoneNumber,
			email: order.email,
		});
		setPaymentType(order.paymentType);

		if (order.shippingAddress.shopLocation)
			setShopLocation(() =>
				toJS(
					shopsStore.currentShop.shopLocations.find(
						(item) =>
							item.location.replace(/<br \/>/g, '') ===
							order.shippingAddress.shopLocation,
					),
				),
			);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		if (cartStore.currentCart.cart.length !== 0) {
			cartStore.getCartItemsByIds();
		}
	}, [cartStore.currentCart.id]);

	useEffect(() => {
		if (settlement.DeliveryCity) {
			novaPoshtaStore.getStreets('A', settlement.Ref);
		}
	}, [settlement]);

	useEffect(() => {
		if (currentCustomer.id)
			getByCustomer(shopsStore.currentShop.id, currentCustomer.id, 1);
	}, [currentCustomer.id]);

	return (
		<div className="checkout-page">
			<Loader type="linear-top-full" enabled={loading} />
			<div className="checkout-page__header-wrapper">
				<PageHeading headingText={t('checkout-page.title')} />
				<NavigateBackButton />
			</div>

			{minOrderAmount && totalPrice < minOrderAmount && (
				<div className="checkout-page__minimum">
					<InfoIcon />
					<p>{`${t('checkout-page.minimum')}: ${minOrderAmount} ${
						shopsStore.currentShop.currencySymbol
					}`}</p>
				</div>
			)}

			<BlockTitle
				number={1}
				title={t('checkout-page.block-titles.contact-details')}
			/>
			{orders.length > 0 && (
				<FormControlLabel
					sx={{ marginBottom: '16px' }}
					control={
						<Checkbox
							checked={isCheckboxChecked}
							onChange={() => {
								handleClickCheckbox();
								getUserInfoFromLastOrder();
							}}
							sx={{
								color: 'var(--global-grey-30)',
								'&.Mui-checked': {
									color: 'var(--global)',
								},
							}}
						/>
					}
					label={t('checkout-page.order.get-user-data')}
				/>
			)}
			<ContactDetails
				userInfo={toJS(userInfo)}
				setUserInfo={setUserInfo}
				errors={userInfoErrors}
				defaultCountry={language === 'uk' ? 'ua' : language}
				getError={getError}
				validate={validateUserInfo}
			/>
			<ProductsPreview
				products={products}
				currencySymbol={shopsStore.currentShop.currencySymbol}
			/>
			<BlockTitle number={2} title={t('checkout-page.block-titles.delivery')} />
			<Pickup
				settlement={settlement}
				setSettlement={handleUpdateSettlement}
				shopLocation={shopLocation || {}}
				setShopLocation={setShopLocation}
				deliveryType={deliveryType}
				setDeliveryType={setDeliveryType}
				warehouse={warehouse}
				setWarehouse={setWarehouse}
				destination={destination}
				setDestination={setDestination}
				address={address}
				setAddress={setAddress}
				getError={getError}
				validate={validate}
				errors={errors}
			/>
			{paymentTypes?.length > 0 && (
				<>
					<BlockTitle
						number={3}
						title={t('checkout-page.block-titles.payment')}
					/>
					<Payments
						shop={toJS(shopsStore.currentShop)}
						type={paymentType}
						method={paymentMethod}
						handleChangeMethod={setPaymentMethod}
						handleChangeType={setPaymentType}
					/>
				</>
			)}
			<BlockTitle
				number={4}
				title={t('checkout-page.block-titles.recipient-details')}
			/>
			<FormControlLabel
				sx={{ marginBottom: '16px' }}
				control={
					<Checkbox
						checked={iAmRecipient}
						onChange={() => setAmIRecipient(!iAmRecipient)}
						sx={{
							color: 'var(--global-grey-30)',
							'&.Mui-checked': {
								color: 'var(--global)',
							},
						}}
					/>
				}
				label={t('checkout-page.order.recipient')}
			/>
			<ContactDetails
				userInfo={toJS(iAmRecipient ? userInfo : recipientInfo)}
				setUserInfo={iAmRecipient ? setUserInfo : setRecipientInfo}
				errors={iAmRecipient ? userInfoErrors : errors}
				defaultCountry={language === 'uk' ? 'ua' : language}
				getError={getError}
				validate={iAmRecipient ? validateUserInfo : validate}
			/>
			<InputLabel
				sx={{
					color: 'var(--global-black-60)',
					fontSize: '12px',
					mb: '4px',
				}}
				htmlFor="comment"
			>
				{t('checkout-page.fields.comment')}
			</InputLabel>
			<TextField
				sx={{ mb: 2 }}
				fullWidth
				id="comment"
				value={comment}
				name="comment"
				multiline
				rows={4}
				inputProps={{ maxLength: 255 }}
				onChange={(value) => setComment(value.target.value)}
			/>
			<InTotal
				products={products}
				disableButton={totalPrice < minOrderAmount}
				action={handleCreateOrder}
				currencySymbol={shopsStore.currentShop.currencySymbol}
				hasErrors={
					userInfoErrors.length > 0 || (!iAmRecipient && errors.length > 0)
				}
			/>
		</div>
	);
});
