import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import './index.scss';

export function NavigateBackButton({ handler }) {
	const navigate = useNavigate();

	const handleGoBack = handler || (() => navigate(-1));

	return (
		<button
			className="back-button"
			aria-label="Go back"
			type="button"
			onClick={handleGoBack}
		>
			<ArrowBackRoundedIcon />
		</button>
	);
}

NavigateBackButton.defaultProps = {
	handler: null,
};

NavigateBackButton.propTypes = {
	handler: PropTypes.func,
};
