import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import './index.scss';

export const ErrorPage = observer(() => {
	const { t } = useTranslation();
	const telegramBotUsername = sessionStorage.getItem('telegramBotUsername');

	return (
		<div className="error-page">
			<div className="error-page__content">
				<p className="error-page__message">{t('error-page.message')}</p>
				<Link to={`/s/${telegramBotUsername}`} className="error-page__link">
					{t('error-page.link')}
				</Link>
			</div>
		</div>
	);
});
