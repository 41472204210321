import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Outlet, Navigate, ScrollRestoration } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header } from '../../common/Header';
import { Loader } from '../../common/Loader';
import { useStores } from '../../../stores/context';
import { SideBarLayout } from '../SideBarLayout';
import { ProductsFilters } from '../../../screens/ProductsPage/components/ProductsFilters';
import { SideMenu } from '../../common/SideMenu';
import AuthModal from '../../common/AuthModal';
import PoweredByIcon from '../../common/PoweredByIcon';
import { ScrollTopPageButton } from '../../common/ScrollTopPageButton';
import maintenanceImg from '../../../assets/logos/maintenance.svg';
import useCustomerLogic from '../../../utils/hooks/useCustomerLogic';
import useInjectScript from '../../../utils/useIngectScripts';
import './index.scss';

const DefaultLayout = observer(() => {
	const { appStore, productsStore, shopsStore } = useStores();
	const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
	const { hasError, t } = useCustomerLogic();
	const { i18n } = useTranslation();

	if (hasError) {
		return <Navigate to="/error" replace />;
	}

	if (shopsStore.currentShop.status === 'paused') {
		return (
			<div className="maintenance">
				<div className="maintenance__logo">
					<PoweredByIcon en={i18n.language === 'en'} />
				</div>
				<h3>{t('maintenance')}</h3>
				<img src={maintenanceImg} alt="maintenance" width="100px" />
			</div>
		);
	}

	const getScriptIds = (scripts) => {
		const scriptIds = {
			googleAnalyticsId: null,
			facebookPixelId: null,
		};

		scripts.forEach((script) => {
			if (script.type === 'googleAnalytics') {
				scriptIds.googleAnalyticsId = script.id;
			} else if (script.type === 'facebookPixel') {
				scriptIds.facebookPixelId = script.id;
			}
		});

		return scriptIds;
	};

	const { googleAnalyticsId, facebookPixelId } = getScriptIds(
		shopsStore.currentShop.scripts || [],
	);

	useInjectScript({ googleAnalyticsId, facebookPixelId });

	if (shopsStore.currentShop.language) {
		return (
			<div className="wrapper">
				<div
					className={`page-overlay ${
						appStore.isOverlayVisible && 'page-overlay_state_visible'
					}`}
				/>
				<SideBarLayout
					isSideBarOpen={isSideMenuOpen}
					isSideMenu
					isRenderBrand={Boolean(shopsStore.currentShop?.isRenderBrand)}
					setIsSideBarOpen={setIsSideMenuOpen}
					setIsOverlayVisible={appStore.setIsOverlayVisible}
					renderChildren={(handleCloseSideBar) => (
						<SideMenu handleCloseSideMenu={handleCloseSideBar} />
					)}
				/>
				<SideBarLayout
					isSideBarOpen={productsStore.isFiltersMenuOpen}
					setIsSideBarOpen={productsStore.setIsFiltersMenuOpen}
					setIsOverlayVisible={appStore.setIsOverlayVisible}
					renderChildren={(handleCloseSideBar) => (
						<ProductsFilters handleCloseSideBar={handleCloseSideBar} />
					)}
				/>
				<Header
					setIsOverlayVisible={appStore.setIsOverlayVisible}
					setIsSideMenuOpen={setIsSideMenuOpen}
				/>
				<Loader type="linear-top" enabled={appStore.loading} />
				<main className="main-side">
					<div className="main-side__wrapper">
						<Outlet />
						<ScrollRestoration getKey={(location) => location.pathname} />
					</div>
				</main>
				<ScrollTopPageButton />
				<AuthModal
					open={appStore.isAuthModalOpen}
					handleClose={() => appStore.setIsAuthModalOpen(false)}
				/>
			</div>
		);
	}

	return <Loader type="linear-top-full" enabled />;
});

export default DefaultLayout;
