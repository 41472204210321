import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export function StatusLabel({ status }) {
	const { t } = useTranslation();
	const getColor = (currentStatus) => {
		switch (currentStatus) {
			case 'completed':
				return 'green';
			case 'canceled':
				return 'red';
			default:
				return 'orange';
		}
	};

	const getText = (currentStatus) => {
		switch (currentStatus) {
			case 'completed':
				return t('orders-tile.status.completed');
			case 'canceled':
				return t('orders-tile.status.canceled');
			default:
				return t('orders-tile.status.processing');
		}
	};

	return <span style={{ color: getColor(status) }}>{getText(status)}</span>;
}

StatusLabel.propTypes = {
	status: PropTypes.string.isRequired,
};
