import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import { useStores } from '../../../stores/context';
import './index.scss';

export const AddToWishlistButton = observer(
	({ productId, width = '48px', height = '48px' }) => {
		const {
			wishlistStore: { currentWishlist, updateWishlist },
			customerStore,
			appStore,
		} = useStores();

		const { wishlist } = toJS(currentWishlist);

		const handleAddToWishlist = (event) => {
			event.preventDefault();
			if (!customerStore.isLoggedIn) {
				appStore.setIsAuthModalOpen(true);
			} else {
				const list = wishlist ? [...wishlist] : [];
				const index = list.indexOf(productId);
				if (index !== -1) list.splice(index, 1);
				else list.push(productId);
				updateWishlist(list);
			}
		};

		return (
			<button
				style={{ width, height }}
				className="like-button"
				aria-label="Add to wishlist"
				type="button"
				onClick={handleAddToWishlist}
			>
				{wishlist?.includes(productId) ? (
					<FavoriteOutlinedIcon />
				) : (
					<FavoriteBorderOutlinedIcon />
				)}
			</button>
		);
	},
);

AddToWishlistButton.propTypes = {
	productId: PropTypes.number.isRequired,
	width: PropTypes.string,
	height: PropTypes.string,
};
