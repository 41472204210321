import { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import { useStores } from '../../../../stores/context';
import { Input } from '../../../../components/shared/Input';
import { InputAutocomplete } from '../../../../components/shared/InputAutocomplete';
import './index.scss';

export const AddressPicker = observer(
	({ address, setAddress, errors, validate, getError, settlementRef }) => {
		const { t } = useTranslation();
		const { novaPoshtaStore } = useStores();

		const streets = toJS(novaPoshtaStore.streets);

		const handleUpdateAddress = (field, value) => {
			setAddress({ ...address, [field]: value });
		};

		useEffect(() => {
			return () =>
				setAddress({
					street: '',
					building: '',
					entrance: '',
					apartment: '',
				});
		}, []);

		return (
			<div className="address-picker">
				<div className="address-picker__street">
					<p className="address-picker__label">
						{t('checkout-page.fields.street')}
					</p>
					<InputAutocomplete
						value={address.street}
						loading={novaPoshtaStore.loading}
						onChange={(newValue) => handleUpdateAddress('street', newValue)}
						onInputChange={(newInputValue) => {
							if (newInputValue.length)
								novaPoshtaStore.getStreets(newInputValue, settlementRef);
						}}
						options={streets.map((item) => item.Present)}
					/>
				</div>
				<Input
					name="building"
					value={address.building}
					updateValue={(value) => handleUpdateAddress('building', value)}
					label={t('checkout-page.fields.building')}
					type="text"
					validate={() => validate('building', address.building)}
					error={
						errors.includes('building')
							? getError(t('checkout-page.fields.building'))
							: ''
					}
				/>
				<Input
					name="entrance"
					value={address.entrance}
					updateValue={(value) => handleUpdateAddress('entrance', value)}
					label={t('checkout-page.fields.entrance')}
					type="text"
				/>
				<Input
					name="apartment"
					value={address.apartment}
					updateValue={(value) => handleUpdateAddress('apartment', value)}
					label={t('checkout-page.fields.apartment')}
					type="number"
				/>
			</div>
		);
	},
);

AddressPicker.defaultProps = {
	streets: [],
	settlementRef: '',
};

AddressPicker.propTypes = {
	streets: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
	address: PropTypes.instanceOf(Object).isRequired,
	setAddress: PropTypes.func.isRequired,
	getError: PropTypes.func.isRequired,
	validate: PropTypes.func.isRequired,
	errors: PropTypes.arrayOf(PropTypes.string).isRequired,
	settlementRef: PropTypes.string,
};
