import PropTypes from 'prop-types';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import BlurLinearRoundedIcon from '@mui/icons-material/BlurLinearRounded';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import ShareLocationRoundedIcon from '@mui/icons-material/ShareLocationRounded';
import { cartCounterFormater } from '../../../utils/helpers';
import { useStores } from '../../../stores/context';
import { ContactInformation } from '../../shared/ContactInformation';
import { ShopLogo } from '../../shared/ShopLogo';
import './index.scss';

export const SideMenu = observer(({ handleCloseSideMenu }) => {
	const {
		shopsStore,
		cartStore: { currentCart },
		appStore,
		customerStore,
	} = useStores();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { telegramBotUsername } = useParams();
	const cartCounter = toJS(currentCart.cart).length;

	const handleProtectedRoute = (path) => {
		if (!customerStore.isLoggedIn) {
			appStore.setIsAuthModalOpen(true);
		} else {
			navigate(`/s/${telegramBotUsername}/${path}`);
		}
		handleCloseSideMenu();
	};

	const shouldShowContactUs = !!shopsStore.currentShop.contactUsUrl;
	const shouldShowAboutUs =
		shopsStore.currentShop.images.length > 0 ||
		shopsStore.currentShop.description;
	const shouldShowShopLocations =
		shopsStore.currentShop.shopLocations.length > 0;
	const shouldShowBorderedSection =
		shouldShowContactUs || shouldShowAboutUs || shouldShowShopLocations;

	return (
		<div>
			<div className="side-menu__header">
				<div className="side-menu__header-wrapper">
					<ShopLogo />
					<p className="side-menu__header-title">
						{shopsStore.currentShop.title}
					</p>
				</div>
				<button
					className="side-menu__close"
					aria-label="Close menu"
					type="button"
					onClick={handleCloseSideMenu}
				>
					<CloseRoundedIcon />
				</button>
			</div>
			<div className="side-menu__body">
				<ul className="side-menu__list">
					<li className="side-menu__item">
						<Link to={`/s/${telegramBotUsername}/`}>
							<button
								className="side-menu__button"
								aria-label="Go to the catalog"
								type="button"
								onClick={handleCloseSideMenu}
							>
								<span>
									<BlurLinearRoundedIcon />
								</span>
								{t('side-menu.catalog-button')}
							</button>
						</Link>
					</li>
					<li className="side-menu__item">
						<Link to={`/s/${telegramBotUsername}/categories`}>
							<button
								className="side-menu__button"
								aria-label="Go to the categories"
								type="button"
								onClick={handleCloseSideMenu}
							>
								<span>
									<CategoryOutlinedIcon />
								</span>
								{t('side-menu.categories-button')}
							</button>
						</Link>
					</li>
					<li className="side-menu__item">
						<button
							className="side-menu__button"
							aria-label="Go to the wishlist"
							type="button"
							onClick={() => handleProtectedRoute('wishlist')}
						>
							<span>
								<FavoriteBorderIcon />
							</span>
							{t('side-menu.wishlist-button')}
						</button>
					</li>
					<li className="side-menu__item">
						<button
							className="side-menu__button"
							aria-label="Go to the orders"
							type="button"
							onClick={() => handleProtectedRoute('orders')}
						>
							<span>
								<HistoryRoundedIcon />
							</span>
							{t('side-menu.orders-button')}
						</button>
					</li>
					<li className="side-menu__item">
						<button
							className="side-menu__button"
							aria-label="Go to the cart"
							type="button"
							onClick={() => handleProtectedRoute('cart')}
						>
							<span>
								<ShoppingCartOutlinedIcon />
							</span>
							{t('side-menu.cart-button')}
							{cartCounter > 0 && (
								<span className="side-menu__counter">
									{cartCounterFormater(cartCounter)}
								</span>
							)}
						</button>
					</li>
					{shouldShowBorderedSection && (
						<section className="side-menu__bordered-section">
							{shouldShowContactUs && (
								<li className="side-menu__item">
									<a href={toJS(shopsStore.currentShop).contactUsUrl}>
										<button
											className="side-menu__button"
											aria-label="Contact Us"
											type="button"
											onClick={handleCloseSideMenu}
										>
											<span>
												<SendOutlinedIcon />
											</span>
											{t('side-menu.contact-us-button')}
										</button>
									</a>
								</li>
							)}
							{shouldShowAboutUs && (
								<li className="side-menu__item">
									<Link to={`/s/${telegramBotUsername}/about-us`}>
										<button
											className="side-menu__button"
											aria-label="Go to the about us page"
											type="button"
											onClick={handleCloseSideMenu}
										>
											<span>
												<InfoOutlinedIcon />
											</span>
											{t('side-menu.about-us-button')}
										</button>
									</Link>
								</li>
							)}
							{shouldShowShopLocations && (
								<li className="side-menu__item">
									<Link to={`/s/${telegramBotUsername}/shop-adresses`}>
										<button
											className="side-menu__button"
											aria-label="Go to the shop adresses page"
											type="button"
											onClick={handleCloseSideMenu}
										>
											<span>
												<ShareLocationRoundedIcon />
											</span>
											{t('side-menu.shop-locations-button')}
										</button>
									</Link>
								</li>
							)}
						</section>
					)}
				</ul>
				{(shopsStore.currentShop.telephoneNumbers.length > 0 ||
					shopsStore.currentShop.socialNetworkLinks.length > 0) && (
					<ContactInformation />
				)}
			</div>
		</div>
	);
});

SideMenu.propTypes = {
	handleCloseSideMenu: PropTypes.func.isRequired,
};
