import { flow, getEnv } from 'mobx-state-tree';

const ProductsStoreActions = (self) => ({
	getAll: flow(function* getAll(params) {
		const { api } = getEnv(self);
		try {
			self.toggleLoading(true);
			const {
				data: { items, links, meta },
			} = yield api.products.getAll(params);
			self.products = items;
			self.links = links;
			self.meta = meta;
			self.toggleLoading(false);
		} catch (error) {
			self.toggleLoading(false);
			window.location.href = '/error';
		}
	}),
	getOneById: flow(function* getOneById(productId) {
		const { api } = getEnv(self);
		try {
			self.toggleLoading(true);
			const { data } = yield api.products.getOneById(productId);
			self.currentProduct = data;
			self.toggleLoading(false);
		} catch (error) {
			self.toggleLoading(false);
			window.location.href = '/error';
		}
	}),
	findParentIfExist: flow(function* findParentIfExist(productId) {
		const { api } = getEnv(self);
		try {
			self.toggleLoading(true);
			const { data } = yield api.products.findParentIfExist(productId);
			self.currentProduct = data;
			self.toggleLoading(false);
		} catch (error) {
			self.toggleLoading(false);
			window.location.href = '/error';
		}
	}),
	searchByTitle: flow(function* searchByTitle(params) {
		const { api } = getEnv(self);
		try {
			const { data } = yield api.products.searchByTitle(params);
			self.searchResult = data;
		} catch (error) {
			console.error(error);
		}
	}),
	getByIds: flow(function* getByIds(productIds) {
		const { api } = getEnv(self);
		try {
			self.toggleLoading(true);
			const { data } = yield api.products.getByIds(productIds);
			self.products = data;
			self.toggleLoading(false);
		} catch (error) {
			self.toggleLoading(false);
			console.error(error);
		}
	}),
	toggleLoading: (value) => {
		self.loading = value;
	},
	setSearchQuery: (value) => {
		self.searchQuery = { ...self.searchQuery, ...value };
	},
	resetSearchQuery: () => {
		self.searchQuery = {};
	},
	resetSearchResult: () => {
		self.searchResult = {};
	},
	resetCurrentProduct: () => {
		self.currentProduct = { id: 0 };
	},
	setIsFiltersMenuOpen: (value) => {
		self.isFiltersMenuOpen = value;
	},
	setActiveVariant: (value) => {
		self.activeVariant = value;
	},
	resetActiveVariant: () => {
		self.activeVariant = { id: 0 };
	},
});

export default ProductsStoreActions;
