/* eslint-disable react/no-danger */
import { useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import DOMPurify from 'dompurify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { toJS } from 'mobx';
import { useStores } from '../../../../stores/context';
import { CheckoutTile } from '../../../CheckoutPage/components/CheckoutTile';
import { StatusLabel } from '../../../../components/shared/StatusLabel';
import './index.scss';

export const OrdersTile = observer(({ item, currencySymbol }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { telegramBotUsername } = useParams();
	const {
		cartStore: { updateCart, currentCart, products },
	} = useStores();

	const [isItemCollapsed, setIsItemCollapsed] = useState(true);

	const paymentTypes = {
		offlineCard: 'offlineCard',
		offlineCash: 'offlineCash',
		onlinePayment: 'onlinePayment',
		cardPrepay: 'cardPrepay',
		cardPartialPrepay: 'cardPartialPrepay',
	};

	const getImageUrl = useCallback((orderItem) => {
		if (!orderItem || !orderItem.product || !orderItem.product.images) {
			return process.env.REACT_APP_DEFAULT_IMAGE_URL;
		}

		const sortedImages = orderItem.product.images.length
			? [...orderItem.product.images].sort((a, b) => a.id - b.id)
			: [
					{
						regularUrl: process.env.REACT_APP_DEFAULT_IMAGE_URL,
					},
			  ];

		return sortedImages?.[0]?.regularUrl || sortedImages?.[0]?.originalUrl;
	}, []);

	const getPaymentType = () => {
		let paymentTypeRow = '';

		switch (item.paymentType) {
			case paymentTypes.onlinePayment:
				paymentTypeRow = t('orders-tile.payment-type-online');
				break;

			case paymentTypes.offlineCash:
				paymentTypeRow = t('orders-tile.payment-type-offline-cash');
				break;

			case paymentTypes.offlineCard:
				paymentTypeRow = t('orders-tile.payment-type-offline-card');
				break;

			case paymentTypes.cardPrepay:
				paymentTypeRow = t('orders-tile.payment-type-prepay');
				break;

			case paymentTypes.cardPartialPrepay:
				paymentTypeRow = t('orders-tile.payment-type-partial-prepay');
				break;

			default:
				break;
		}

		return paymentTypeRow;
	};

	const deliveryTypes = {
		selfPickup: 'selfPickup',
		delivery: 'delivery',
		novaPoshtaCourier: 'novaPoshtaCourier',
		novaPoshtaSelfPickup: 'novaPoshtaSelfPickup',
		electronicDelivery: 'electronicDelivery',
	};

	const getDeliveryType = () => {
		let deliveryRow = '';
		let addressRow = '';
		let cityRow = '';

		switch (item.shippingAddress.type) {
			case deliveryTypes.selfPickup:
				deliveryRow = t('orders-tile.delivery-type-self-pickup');
				addressRow =
					item.shippingAddress.shopLocation || t('orders-tile.not-specified');
				break;

			case deliveryTypes.novaPoshtaCourier:
				deliveryRow = t('orders-tile.delivery-type-novaPoshta-courier');
				cityRow = item.shippingAddress.city || t('orders-tile.not-specified');
				addressRow =
					[
						item.shippingAddress.street,
						item.shippingAddress.building,
						item.shippingAddress.entrance,
						item.shippingAddress.apartment,
					].join(', ') || t('orders-tile.not-specified');
				break;

			case deliveryTypes.novaPoshtaSelfPickup:
				deliveryRow = t('orders-tile.delivery-type-novaPoshta-self-pickup');
				cityRow = item.shippingAddress.city || t('orders-tile.not-specified');
				addressRow =
					item.shippingAddress.npDepartment || t('orders-tile.not-specified');
				break;

			case deliveryTypes.delivery:
				deliveryRow = t('orders-tile.delivery-type-delivery');
				addressRow =
					item.shippingAddress.destination || t('orders-tile.not-specified');
				break;

			case deliveryTypes.electronicDelivery:
				deliveryRow = t('orders-tile.delivery-type-electronic-delivery');
				addressRow =
					item.shippingAddress.destination || t('orders-tile.not-specified');
				break;

			default:
				break;
		}

		return { deliveryRow, addressRow, cityRow };
	};

	const repeatOrder = async () => {
		const cart = toJS(currentCart.cart);
		const newItems = [];
		item.orderItems
			.filter(({ product }) => product)
			.forEach(({ product, quantity }) => {
				if (!products.find((p) => p.id === product?.id)) {
					newItems.push({ id: product.id, quantity });
				}
			});
		await updateCart([...cart, ...newItems]);
		setIsItemCollapsed(!isItemCollapsed);
		navigate(`/s/${telegramBotUsername}/cart`);
	};

	const orderDescription = Object.entries({
		[t('orders-tile.your-order')]: `#${item.orderNumber}`,
		[t('orders-tile.status.label')]: <StatusLabel status={item.status} />,
		[t('orders-tile.total-amount')]: item.totalAmount,
		[t('orders-tile.delivery-type')]: getDeliveryType().deliveryRow,
		[t('orders-tile.full-name')]: item.fullName,
		[t('orders-tile.phone')]: `+${item.phoneNumber}`,
		[t('orders-tile.city')]: getDeliveryType().cityRow,
		[t('orders-tile.address')]: (
			<span
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(getDeliveryType().addressRow),
				}}
			/>
		),
		[t('orders-tile.payment-type')]: getPaymentType(),
		[t('orders-tile.paid')]: item.isPayed
			? t('orders-tile.yes')
			: t('orders-tile.no'),
	});

	const dateObject = new Date(item.createdAt);
	const dateString = `${dateObject.toDateString()} ${dateObject.toLocaleTimeString()}`;

	return (
		<li className="order-tile">
			<div className="heading">
				<div className="heading__flex">
					<div className="heading__status">
						<p className="heading__title">{t('orders-tile.title')}</p>
						<p className="heading__label">{dateString}</p>
					</div>
					{isItemCollapsed && (
						<div className="heading__price">
							<p className="heading__label"> {t('orders-tile.total-amount')}</p>
							<p className="heading__value">{`${item.totalAmount} ${currencySymbol}`}</p>
						</div>
					)}
					{isItemCollapsed && (
						<ul className="heading__goods">
							{item.orderItems.map((orderItem) =>
								orderItem.product ? (
									<Link
										to={`/s/${telegramBotUsername}/${orderItem.product.id}`}
										state={{
											variantParentId: orderItem.product.variantParentId,
										}}
										className="heading__goods-item"
										key={orderItem.id}
									>
										<img src={getImageUrl(orderItem)} alt="img" />
									</Link>
								) : (
									''
								),
							)}
						</ul>
					)}
					<button
						type="button"
						className="order-tile__collapse-button"
						onClick={() => setIsItemCollapsed(!isItemCollapsed)}
					>
						{isItemCollapsed ? (
							<KeyboardArrowUpRoundedIcon />
						) : (
							<KeyboardArrowDownRoundedIcon />
						)}
					</button>
				</div>
			</div>
			{!isItemCollapsed && (
				<div className="order-details">
					<div className="order-details__payment">
						<ul className="payment-info">
							{orderDescription.map(([key, value]) => {
								return (
									<li key={key} className="payment-info__item">
										<span className="payment-info__label">{key}</span>
										<span className="payment-info__value">{value}</span>
									</li>
								);
							})}
						</ul>
					</div>
					<div className="order-details__goods">
						<div className="goods-list">
							<p className="goods-list__header">
								{t('orders-tile.goods-list')}
							</p>
							<ul className="goods-list__items">
								{item.orderItems.map((orderItem) =>
									orderItem.product ? (
										<li key={orderItem.id}>
											<CheckoutTile
												item={{
													...orderItem.product,
													image: getImageUrl(orderItem),
													quantity: orderItem.quantity,
												}}
												currencySymbol={currencySymbol}
											/>
										</li>
									) : (
										''
									),
								)}
							</ul>
						</div>
					</div>
					<button
						type="button"
						className="repeate-button"
						onClick={repeatOrder}
					>
						{t('orders-tile.repeate-button')}
					</button>
				</div>
			)}
		</li>
	);
});

OrdersTile.propTypes = {
	item: PropTypes.instanceOf(Object).isRequired,
	currencySymbol: PropTypes.string.isRequired,
};
