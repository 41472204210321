import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../../../../stores/context';
import './index.scss';

export const FooterFilter = observer(({ goBackAction, showProductsAction }) => {
	const { productsStore } = useStores();
	const { t } = useTranslation();

	return (
		<div className="footer-filter">
			<p className="footer-filter__caption">
				{`${t('footer-filter.caption-first-part')} ${
					productsStore.meta.totalItems
				} ${t('footer-filter.caption-second-part')}`}
			</p>
			<div className="footer-filter__buttons-wrapper">
				{goBackAction && (
					<button
						className="footer-filter__back-button"
						aria-label="Go back"
						type="button"
						onClick={goBackAction}
					>
						{t('footer-filter.back-button')}
					</button>
				)}
				{showProductsAction && (
					<button
						className={`footer-filter__show-button ${
							goBackAction && 'footer-filter__show-button--margin'
						}`}
						aria-label="Show products"
						type="button"
						onClick={showProductsAction}
					>
						{t('footer-filter.show-button')}
					</button>
				)}
			</div>
		</div>
	);
});

FooterFilter.defaultProps = {
	goBackAction: null,
	showProductsAction: null,
};

FooterFilter.propTypes = {
	goBackAction: PropTypes.func,
	showProductsAction: PropTypes.func,
};
