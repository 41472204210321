import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useStores } from '../../../stores/context';
import useDebounce from '../../../utils/hooks/useDebounce';
import { SearchSuggest } from './components/SearchSuggest';
import './index.scss';

export const SearchForm = observer(({ setIsOverlayVisible }) => {
	const {
		productsStore: { searchByTitle, searchResult, resetSearchQuery },
		shopsStore,
	} = useStores();
	const { t } = useTranslation();
	const searchRef = useRef();

	const [isSearchInputActive, setIsSearchInputActive] = useState(false);
	const [searchInputText, setSearchInputText] = useState('');

	const handleToggleSearchInput = (value) => {
		setIsOverlayVisible(value);
		setIsSearchInputActive(value);
	};

	const handleCleanSearchInput = () => {
		setSearchInputText('');
		resetSearchQuery();
	};

	const handleChangeSearchInput = (event) => {
		if (searchInputText && event.target.value === '') resetSearchQuery();
		setSearchInputText(event.target.value);
	};

	useDebounce(
		() => {
			if (searchInputText)
				searchByTitle({
					searchValue: searchInputText,
					shopId: shopsStore.currentShop?.id,
				});
		},
		[searchInputText],
		400,
	);

	useEffect(() => {
		const handleClick = (event) => {
			if (
				searchRef.current &&
				!searchRef.current.contains(event.target) &&
				isSearchInputActive
			) {
				handleToggleSearchInput(false);
			}
		};

		document.addEventListener('click', handleClick, true);

		return () => {
			document.removeEventListener('click', handleClick, true);
		};
	}, [searchRef, isSearchInputActive]);

	return (
		<div
			ref={searchRef}
			className={`search-form ${
				isSearchInputActive && 'search-form__state--unfolded'
			}`}
		>
			<div className="search-form__inner">
				<div
					className={`search-form__input-wrapper ${
						isSearchInputActive && 'search-form__input-wrapper--active'
					}`}
				>
					<button
						className={`search-form__back ${
							isSearchInputActive && 'search-form__back--visible'
						}`}
						aria-label="Cancel search"
						type="button"
						onClick={() => handleToggleSearchInput(false)}
					>
						<ArrowBackRoundedIcon />
					</button>
					<input
						className="search-form__input"
						name="search"
						type="text"
						onFocus={() => handleToggleSearchInput(true)}
						placeholder={t('search-form.placeholder')}
						value={searchInputText}
						onChange={handleChangeSearchInput}
					/>
					<button
						className={`search-form__clear ${
							searchInputText && 'search-form__clear--visible'
						}`}
						aria-label="Clear search"
						type="button"
						onClick={handleCleanSearchInput}
					>
						<ClearRoundedIcon />
					</button>
				</div>
				{isSearchInputActive && (
					<SearchSuggest
						searchInputText={searchInputText}
						setSearchInputText={setSearchInputText}
						searchResult={toJS(searchResult)}
						handleCancelSearchInput={() => handleToggleSearchInput(false)}
					/>
				)}
			</div>
		</div>
	);
});

SearchForm.propTypes = {
	setIsOverlayVisible: PropTypes.func.isRequired,
};
