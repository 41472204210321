/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { IconButton } from '@mui/material';
import { NavLink, useParams } from 'react-router-dom';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { formatPrice, formatProductTitle } from '../../../../utils/helpers';
import './index.scss';

export function CartTile({
	item,
	removeProduct,
	updateQuantity,
	currencySymbol,
}) {
	const { telegramBotUsername } = useParams();

	const [quantity, setQuantity] = useState(item.quantity);
	const handleRemoveProduct = (event) => {
		event.preventDefault();
		const { id, title } = item;
		removeProduct({ id, title });
	};

	const handleUpdateQuantity = (value) => {
		setQuantity(value || '');
		if (value) updateQuantity(item.id, value);
	};

	return (
		<div className="card-tile">
			<NavLink
				to={`/s/${telegramBotUsername}/${item?.id}`}
				state={{ variantParentId: item?.variantParentId }}
				className="card-tile__item"
			>
				{item?.image ? (
					<span>
						<img src={item?.image} alt="img" />
					</span>
				) : (
					<div className="card-tile__skeleton" />
				)}
				<p>{formatProductTitle(item)}</p>
				<IconButton
					onClick={handleRemoveProduct}
					className="card-tile__trash-button"
				>
					<DeleteOutlinedIcon />
				</IconButton>
			</NavLink>
			<div className="card-tile__footer">
				<div className="card-tile__quantity-selector">
					<IconButton
						disabled={item.quantity < 2}
						onClick={() => handleUpdateQuantity(item.quantity - 1)}
						className="card-tile__operator-button"
					>
						<RemoveIcon />
					</IconButton>
					<input
						type="text"
						value={quantity}
						onChange={(event) => {
							const value = Number(
								event.target.value.replace(/[^\d]/g, '').replace(/^0+/, ''),
							);
							const newQuantity = item.stockControl
								? Math.min(value, item.inStock)
								: Math.min(value, 999);
							handleUpdateQuantity(newQuantity);
						}}
						onBlur={() => {
							if (!quantity) setQuantity(item.quantity);
						}}
					/>
					<IconButton
						disabled={
							item.stockControl
								? item.quantity + 1 > item.inStock
								: item.quantity === 999
						}
						onClick={() => handleUpdateQuantity(item.quantity + 1)}
						className="card-tile__operator-button"
					>
						<AddIcon />
					</IconButton>
				</div>
				<div>
					{item.discountPrice ? (
						<p className="card-tile__old-price">{`${formatPrice(
							item.price * item.quantity,
						)} ${currencySymbol}`}</p>
					) : null}
					<p className={`${item.discountPrice ? 'card-tile__new-price' : ''}`}>
						{`${formatPrice(
							(item.discountPrice || item.price) * item.quantity,
						)} ${currencySymbol}`}
					</p>
				</div>
			</div>
		</div>
	);
}

CartTile.propTypes = {
	item: PropTypes.instanceOf(Object).isRequired,
	removeProduct: PropTypes.func.isRequired,
	updateQuantity: PropTypes.func.isRequired,
	currencySymbol: PropTypes.string.isRequired,
};
