import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider, alpha } from '@mui/material/styles';
import './index.scss';

const theme = createTheme({
	palette: {
		primary: {
			main: alpha(
				window?.Telegram?.WebApp?.themeParams?.button_color || '#7b61ff',
				0.2,
			),
		},
	},
});

export function ProductsPagination({
	totalPages,
	currentPage,
	handleChangePage,
}) {
	return (
		<div className="pagination">
			<ThemeProvider theme={theme}>
				<Pagination
					count={totalPages}
					shape="rounded"
					color="primary"
					page={currentPage}
					onChange={handleChangePage}
				/>
			</ThemeProvider>
		</div>
	);
}

ProductsPagination.propTypes = {
	totalPages: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	handleChangePage: PropTypes.func.isRequired,
};
