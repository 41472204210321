export const variantsValueOrder = [
	'XXS',
	'XS',
	'S',
	'M',
	'L',
	'XL',
	'XXL',
	'XXXL',
	'4XL',
	'5XL',
];
