import { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SelectedContainer } from '../SelectedContainer';
import './index.scss';

export function Payments({
	shop,
	handleChangeType,
	type,
	method,
	handleChangeMethod,
}) {
	const { t } = useTranslation();
	const { paymentMethods, paymentTypes } = shop;

	useEffect(() => {
		if (!type) handleChangeType(paymentTypes[0]);
		if (type === 'onlinePayment' && !method && paymentMethods?.length > 0)
			handleChangeMethod(paymentMethods[0].id);
	}, [type]);

	return (
		<div className="payments-container">
			{paymentTypes?.includes('offlineCard') && (
				<SelectedContainer
					title={t('checkout-page.payments.offlineCard')}
					active={type === 'offlineCard'}
					onClick={() => handleChangeType('offlineCard')}
				/>
			)}
			{paymentTypes?.includes('offlineCash') && (
				<SelectedContainer
					title={t('checkout-page.payments.offlineCash')}
					active={type === 'offlineCash'}
					onClick={() => handleChangeType('offlineCash')}
				/>
			)}
			{paymentTypes?.includes('cardPrepay') && (
				<SelectedContainer
					title={t('checkout-page.payments.cardPrepay')}
					active={type === 'cardPrepay'}
					onClick={() => handleChangeType('cardPrepay')}
				/>
			)}
			{paymentTypes?.includes('cardPartialPrepay') && (
				<SelectedContainer
					title={t('checkout-page.payments.cardPartialPrepay')}
					active={type === 'cardPartialPrepay'}
					onClick={() => handleChangeType('cardPartialPrepay')}
				/>
			)}
			{paymentTypes?.includes('onlinePayment') && paymentMethods.length > 0 && (
				<SelectedContainer
					title={t('checkout-page.payments.onlinePayment')}
					active={type === 'onlinePayment'}
					withBorder
					onClick={() => handleChangeType('onlinePayment')}
					item={
						<div>
							{paymentMethods?.map((item) => (
								<SelectedContainer
									key={item.id}
									title={item.name}
									active={method === item.id}
									onClick={() => handleChangeMethod(item.id)}
								/>
							))}
						</div>
					}
				/>
			)}
		</div>
	);
}

Payments.defaultProps = {
	type: null,
	method: null,
};

Payments.propTypes = {
	shop: PropTypes.instanceOf(Object).isRequired,
	handleChangeType: PropTypes.func.isRequired,
	type: PropTypes.string,
	method: PropTypes.number,
	handleChangeMethod: PropTypes.func.isRequired,
};
