import { types } from 'mobx-state-tree';
import { CartProduct, Cart } from './models';
import CartStoreActions from './actions';

const CartStore = types
	.model('CartStore', {
		products: types.optional(types.array(CartProduct), []),
		currentCart: types.optional(Cart, { id: 0 }),
		loading: types.optional(types.boolean, false),
	})
	.actions(CartStoreActions);

export default CartStore;
