import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ScreenshotMonitorRoundedIcon from '@mui/icons-material/ScreenshotMonitorRounded';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { SearchForm } from '../SearchForm';
import { useStores } from '../../../stores/context';
import { cartCounterFormater } from '../../../utils/helpers';
import { ShopLogo } from '../../shared/ShopLogo';
import './index.scss';

export const Header = observer(({ setIsOverlayVisible, setIsSideMenuOpen }) => {
	const { t } = useTranslation();

	const {
		cartStore: { currentCart },
		customerStore,
	} = useStores();

	const { telegramBotUsername } = useParams();

	const handleOpenSideMenu = () => {
		setIsOverlayVisible(true);
		setIsSideMenuOpen(true);
	};

	const handleLogout = () => {
		localStorage.removeItem('accessToken');
		customerStore.setDefaultCustomer();
		customerStore.setIsLoggedIn(false);
		window.location.href = `/s/${telegramBotUsername}`;
	};

	const handleOpenInBrowser = () => {
		const currentUrl = new URL(window.location.href);

		currentUrl.search = '';
		currentUrl.hash = '';

		window.open(currentUrl.toString(), '_blank');
	};

	const cartCounter = toJS(currentCart.cart).length;

	return (
		<header className="header">
			<div className="header__wrapper">
				<div className="header__menu">
					<button
						className="menu-button"
						aria-label="Open menu"
						type="button"
						onClick={handleOpenSideMenu}
					>
						<MenuRoundedIcon />
					</button>
				</div>
				<ShopLogo />
				{window.Telegram.WebApp?.initData && (
					<div className="header__open-browser">
						<button
							className="open-in-browser-button"
							aria-label="Open in browser"
							type="button"
							onClick={handleOpenInBrowser}
						>
							<ScreenshotMonitorRoundedIcon />
						</button>
					</div>
				)}
				{customerStore.isLoggedIn && !window.Telegram.WebApp?.initData && (
					<div className="header__logout">
						<button
							className="logout-button"
							aria-label="Logout"
							type="button"
							onClick={handleLogout}
						>
							{t('logout-button')}
						</button>
					</div>
				)}
				<div className="header__search">
					<SearchForm setIsOverlayVisible={setIsOverlayVisible} />
				</div>
				<div className="header__cart">
					<Link to={`/s/${telegramBotUsername}/cart`}>
						<button
							className="cart-button"
							aria-label="Open cart"
							type="button"
						>
							<ShoppingCartOutlinedIcon />
							{cartCounter > 0 && (
								<span className="cart-button__counter">
									{cartCounterFormater(cartCounter)}
								</span>
							)}
						</button>
					</Link>
				</div>
			</div>
		</header>
	);
});

Header.propTypes = {
	setIsOverlayVisible: PropTypes.func.isRequired,
	setIsSideMenuOpen: PropTypes.func.isRequired,
};
