import { useTranslation } from 'react-i18next';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../stores/context';
import './index.scss';

export const StatusFilter = observer(() => {
	const { productsStore } = useStores();
	const { t } = useTranslation();

	const handleCheckBoxChange = (newValue) =>
		productsStore.setSearchQuery({ sellStatus: newValue, page: 1 });

	return (
		<div className="status-filter">
			<ul className="status-filter__checkbox">
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								checked={productsStore.searchQuery.sellStatus === 'available'}
								sx={{
									'&.Mui-checked': {
										color: 'var(--link-color)',
									},
								}}
							/>
						}
						label={
							<Typography sx={{ fontSize: 14, color: 'var(--global-black)' }}>
								{t('status-filter.status-available')}
							</Typography>
						}
						onChange={() => handleCheckBoxChange('available')}
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={productsStore.searchQuery.sellStatus === 'unavailable'}
								sx={{ '&.Mui-checked': { color: 'var(--link-color)' } }}
							/>
						}
						label={
							<Typography sx={{ fontSize: 14, color: 'var(--global-black)' }}>
								{t('status-filter.status-unavailable')}
							</Typography>
						}
						onChange={() => handleCheckBoxChange('unavailable')}
					/>
				</FormGroup>
			</ul>
		</div>
	);
});
