import { types } from 'mobx-state-tree';
import CategoriesStoreActions from './actions';
import Category from './model';

const CategoriesStore = types
	.model('CategoriesStore', {
		categories: types.optional(types.array(Category), []),
		currentCategory: types.optional(Category, { id: 0 }),
	})
	.actions(CategoriesStoreActions);

export default CategoriesStore;
