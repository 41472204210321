import { flow, getEnv } from 'mobx-state-tree';
import { DEFAULT_CUSTOMER } from './model';

const CustomerStoreActions = (self) => ({
	findOrCreateCustomer: flow(function* findOrCreateCustomer(payload) {
		const { api } = getEnv(self);
		try {
			const { data } = yield api.customers.create(payload);
			self.currentCustomer = data;
		} catch (error) {
			self.currentCustomer = DEFAULT_CUSTOMER;
		}
	}),

	setDefaultCustomer: () => {
		self.currentCustomer = DEFAULT_CUSTOMER;
	},

	setIsLoggedIn: (value) => {
		self.isLoggedIn = value;
	},

	setCurrentCustomer: (customerData) => {
		self.currentCustomer = customerData;
	},

	telegramLogin: flow(function* telegramLogin(telegramData) {
		const { api } = getEnv(self);
		try {
			const { data } = yield api.auth.telegramLogin(telegramData);
			self.currentCustomer = data.customer;
			localStorage.setItem('accessToken', data.accessToken);
			self.setIsLoggedIn(true);
		} catch (error) {
			self.currentCustomer = DEFAULT_CUSTOMER;
			self.setIsLoggedIn(false);
			throw new Error(error?.response?.data?.message || error);
		}
	}),
});

export default CustomerStoreActions;
