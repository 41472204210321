import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../stores/context';
import './index.scss';

export const PriceFilter = observer(({ goBackAction }) => {
	const {
		productsStore: { meta, setSearchQuery },
	} = useStores();

	const { minPrice, maxPrice } = meta.priceRange;
	const [value, setValue] = useState([minPrice, maxPrice]);

	const handleChange = (_, newValue) => {
		setValue(newValue);
	};

	const handleChangeInput = (event, limit) => {
		const res = Number(event.target.value);
		if (limit === 'max') setValue([value[0], res]);
		else setValue([res, value[1]]);
	};

	const handleBlur = useCallback(
		(limit) => {
			if (value[0] > maxPrice && limit === 'min')
				setValue([maxPrice, value[1]]);
			if (value[0] < minPrice && limit === 'min')
				setValue([minPrice, value[1]]);
			if (value[1] > maxPrice && limit === 'max')
				setValue([value[0], maxPrice]);
			if (value[1] < minPrice && limit === 'max')
				setValue([value[0], minPrice]);
		},
		[value, minPrice, maxPrice],
	);

	const handleChangePriceFilter = () => {
		setSearchQuery({ price: value.join('-'), page: 1 });
		goBackAction();
	};

	return (
		<div className="price-filter">
			<div className="price-filter__form">
				<div className="price-filter__inner">
					<input
						className="price-filter__input"
						value={value[0]}
						disabled={!minPrice || !maxPrice}
						onBlur={() => handleBlur('min')}
						onChange={(event) => handleChangeInput(event, 'min')}
					/>
					<span className="price-filter__dash"> — </span>
					<input
						className="price-filter__input"
						value={value[1]}
						disabled={!minPrice || !maxPrice}
						onBlur={() => handleBlur('max')}
						onChange={(event) => handleChangeInput(event, 'max')}
					/>
					<button
						className="price-filter__approve-button"
						aria-label="Approve"
						type="button"
						disabled={!minPrice || !maxPrice}
						onClick={handleChangePriceFilter}
					>
						ok
					</button>
				</div>
				<div className="price-filter__range">
					<Slider
						value={value}
						min={minPrice}
						max={maxPrice}
						disabled={!minPrice || !maxPrice}
						onChange={handleChange}
						sx={{
							color: 'var(--link-color)',
							'& .MuiSlider-thumb': {
								height: 24,
								width: 24,
								backgroundColor: 'var(--link-color)',
								border: '1px solid var(--global-white)',
							},
						}}
					/>
				</div>
			</div>
		</div>
	);
});

PriceFilter.propTypes = {
	goBackAction: PropTypes.func.isRequired,
};
