import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useStores } from '../../stores/context';
import loaderGif from '../../assets/gifs/loaderGif.gif';
import { PageHeading } from '../../components/shared/PageHeading';
import { NavigateBackButton } from '../../components/shared/NavigateBackButton';
import { OrdersTile } from './components/OrdersTile';
import './index.scss';

export const OrdersPage = observer(() => {
	const {
		ordersStore: { orders, loading, getByCustomer },
		customerStore: { currentCustomer },
		shopsStore: { currentShop },
	} = useStores();
	const { t } = useTranslation();
	const { telegramBotUsername } = useParams();

	useEffect(() => {
		if (currentCustomer.id) getByCustomer(currentShop.id, currentCustomer.id);
	}, [currentCustomer.id]);

	const renderOrdersContent = () => {
		if (orders.length)
			return (
				<ul className="order-list">
					{orders.map((order) => (
						<OrdersTile
							currencySymbol={currentShop.currencySymbol}
							item={order}
							key={order.id}
						/>
					))}
				</ul>
			);

		return (
			<div className="orders-page__empty">
				<h4>{t('orders-page.empty')}</h4>
				<Link to={`/s/${telegramBotUsername}/`}>
					<p>{t('orders-page.to-products')}</p>
				</Link>
			</div>
		);
	};

	return (
		<div className="orders-page">
			<div className="orders-page__header">
				<PageHeading headingText={t('orders-page.title')} />
				<NavigateBackButton />
			</div>
			<div className="orders-page__wrapper">
				{loading ? (
					<div className="image-loader">
						<img
							src={loaderGif}
							alt="Loading..."
							style={{
								width: '100px',
								height: '100px',
								marginTop: '100px',
								marginBottom: '200px',
							}}
						/>
					</div>
				) : (
					renderOrdersContent()
				)}
			</div>
		</div>
	);
});
