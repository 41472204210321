import { types } from 'mobx-state-tree';
import Wishlist from './model';
import WishlistStoreActions from './actions';

const WishlistStore = types
	.model('WishlistStore', {
		currentWishlist: types.optional(Wishlist, { id: 0 }),
	})
	.actions(WishlistStoreActions);

export default WishlistStore;
