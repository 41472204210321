/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import './index.scss';

export function ModalPopUp({ onClose, action, title, message, actionTitle }) {
	const { t } = useTranslation();

	return (
		<>
			<div className="dark-bg" onClick={onClose} />
			<div className="centered">
				<div className="modal">
					<div className="modal__header">
						<h5 className="modal__title">{title}</h5>
					</div>
					<button
						type="button"
						className="modal__close-button"
						onClick={onClose}
					>
						<CloseIcon />
					</button>
					<div className="modal__content">{message}</div>
					<div className="modal__actions">
						<button
							type="button"
							className="modal__action-button"
							onClick={action}
						>
							{actionTitle}
						</button>
						<button
							type="button"
							className="modal__cancel-button"
							onClick={onClose}
						>
							{t('modal.cancel-button')}
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

ModalPopUp.propTypes = {
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	action: PropTypes.func.isRequired,
	actionTitle: PropTypes.string.isRequired,
};
