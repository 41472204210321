import { types } from 'mobx-state-tree';
import { Product, Links, Meta, SearchQuery, SearchResult } from './model';
import ProductsStoreActions from './actions';

const ProductsStore = types
	.model('ProductsStore', {
		products: types.optional(types.array(Product), []),
		links: types.optional(Links, {}),
		meta: types.optional(Meta, {}),
		searchQuery: types.optional(SearchQuery, {}),
		searchResult: types.optional(SearchResult, {}),
		isFiltersMenuOpen: types.optional(types.boolean, false),
		currentProduct: types.optional(Product, { id: 0 }),
		activeVariant: types.optional(Product, { id: 0 }),
		loading: types.optional(types.boolean, false),
	})
	.actions(ProductsStoreActions);

export default ProductsStore;
