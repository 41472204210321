import { types } from 'mobx-state-tree';

const DEFAULT_CUSTOMER = {
	id: 'default-id',
	firstName: 'Default',
	lastName: 'User',
	email: 'default@example.com',
	telegramUsername: 'default_user',
	telegramUserId: '0',
	phoneNumber: '1234567890',
};

const Customer = types.model('Customer', {
	id: types.maybeNull(types.union(types.identifierNumber, types.string)),
	firstName: types.maybeNull(types.string),
	lastName: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	telegramUsername: types.maybeNull(types.string),
	telegramUserId: types.maybeNull(types.string),
	phoneNumber: types.maybeNull(types.string),
});

export { DEFAULT_CUSTOMER };

export default Customer;
