/* eslint-disable no-nested-ternary */
import { toJS } from 'mobx';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
	capitalizeFirstLetter,
	categoryTitleFormater,
	sortArrayBy,
} from '../../../../utils/helpers';
import './index.scss';

export function ProductCharacteristics({ category, attributes }) {
	const { t } = useTranslation();
	const { telegramBotUsername } = useParams();

	const getAttributeValueByType = (attribute) => {
		if (attribute.attribute.type === 'color')
			return attribute?.value?.value.split(',')[0];
		if (attribute.attribute.type === 'link')
			return (
				<a
					target="_blank"
					href={attribute?.value?.value.split(',')[1]}
					rel="noreferrer"
				>
					{attribute?.value?.value.split(',')[0]}
				</a>
			);
		return attribute?.value?.value;
	};

	return (
		<section className="characteristics">
			<dl className="characteristics__list">
				<div className="characteristics__item">
					<dt className="characteristics__label">
						<span>{t('characteristics.category')}</span>
					</dt>
					<dd className="characteristics__value">
						<Link to={`/s/${telegramBotUsername}/`} state={toJS(category)}>
							{categoryTitleFormater(category?.title, t) || ''}
						</Link>
					</dd>
				</div>
				{attributes &&
					sortArrayBy(attributes, 'attribute.name').map((attribute) => (
						<div key={attribute?.value?.id} className="characteristics__item">
							<dt className="characteristics__label">
								<span>{capitalizeFirstLetter(attribute?.attribute?.name)}</span>
							</dt>
							<dd className="characteristics__value">
								<span>{getAttributeValueByType(attribute)}</span>
							</dd>
						</div>
					))}
			</dl>
		</section>
	);
}

ProductCharacteristics.defaultProps = {
	category: {},
	attributes: [],
};

ProductCharacteristics.propTypes = {
	category: PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string,
	}),
	attributes: PropTypes.instanceOf(Array),
};
