import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import { Skeleton } from '@mui/material';
import { createTheme, ThemeProvider, alpha } from '@mui/material/styles';
import './index.scss';

const telegramButtonColor =
	window.Telegram.WebApp?.themeParams?.button_color || '#7b61ff';

const theme = createTheme({
	palette: {
		primary: {
			main: telegramButtonColor,
		},
		secondary: {
			main: alpha(telegramButtonColor, 0.6),
		},
	},
});

export function Loader({ type, enabled }) {
	const renderLinearTop = () => {
		return (
			<div className="loader">
				<LinearProgress />
				<div className="loader__overlay" />
			</div>
		);
	};

	const renderLinearTopFull = () => {
		return (
			<div className="loader-full">
				<LinearProgress
					sx={{
						position: 'fixed',
						top: 0,
						left: 0,
						zIndex: 99999,
						width: '100%',
					}}
				/>
				<div className="loader-full__overlay" />
			</div>
		);
	};

	const renderSkeletonProductFull = () => {
		return (
			<div className="loader__product--full">
				<Skeleton
					sx={{
						bgcolor: 'var(--global-20)',
						borderRadius: '4px',
					}}
					variant="rectangular"
					height="144px"
					width="100%"
				/>
				<Skeleton
					sx={{
						bgcolor: 'var(--global-20)',
						borderRadius: '4px',
						marginTop: '10px',
					}}
					variant="rectangular"
					height="32px"
					width="130px"
				/>
				<Skeleton
					sx={{
						bgcolor: 'var(--global-20)',
						borderRadius: '4px',
						marginTop: '8px',
					}}
					variant="rectangular"
					height="22px"
					width="48px"
				/>
				<Skeleton
					sx={{
						bgcolor: 'var(--global-20)',
						borderRadius: '4px',
						marginTop: '40px',
					}}
					variant="rectangular"
					height="16px"
					width="89px"
				/>
			</div>
		);
	};

	const renderSkeletonProductShort = () => {
		return (
			<div className="loader__product--short">
				<Skeleton
					sx={{
						bgcolor: 'var(--global-20)',
						borderRadius: '4px',
					}}
					variant="rectangular"
					height="100%"
					width="30%"
				/>
				<Skeleton
					sx={{
						bgcolor: 'var(--global-20)',
						borderRadius: '4px',
						marginLeft: '10px',
					}}
					variant="rectangular"
					height="32px"
					width="232px"
				/>
			</div>
		);
	};

	const renderLoader = () => {
		switch (type) {
			case 'linear-top':
				return renderLinearTop();
			case 'linear-top-full':
				return renderLinearTopFull();
			case 'skeleton-product-full':
				return renderSkeletonProductFull();
			case 'skeleton-product-short':
				return renderSkeletonProductShort();
			default:
				return '';
		}
	};

	return (
		<ThemeProvider theme={theme}>{enabled && renderLoader()}</ThemeProvider>
	);
}

Loader.propTypes = {
	type: PropTypes.string.isRequired,
	enabled: PropTypes.bool.isRequired,
};
