/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const InputAutocomplete = observer(
	({ value, onChange, options, onInputChange, disabled, loading }) => {
		const { t } = useTranslation();
		const [inputValue, setInputValue] = useState('');

		return (
			<Autocomplete
				loading={loading}
				disabled={disabled}
				loadingText={t('autocomplete.loading-text')}
				disableClearable
				blurOnSelect
				noOptionsText={t('autocomplete.no-options-text')}
				value={value}
				onChange={(event, newValue) => onChange(newValue)}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
					onInputChange(newInputValue);
				}}
				options={options}
				sx={{
					width: '100%',
					marginTop: '16px',
					boxShadow: 'none',
					'& input': { height: '10px', fontSize: '14px' },
				}}
				renderInput={(params) => (
					<TextField
						sx={{ fontSize: '14px' }}
						placeholder={t('autocomplete.placeholder')}
						{...params}
					/>
				)}
				renderOption={(props, option) => (
					<Box component="li" sx={{ fontSize: '14px' }} {...props}>
						{option}
					</Box>
				)}
			/>
		);
	},
);

InputAutocomplete.defaultProps = {
	loading: false,
	disabled: false,
};

InputAutocomplete.propTypes = {
	value: PropTypes.string,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onInputChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(PropTypes.string).isRequired,
};
