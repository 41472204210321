import { useEffect, useState } from 'react';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { scrollToTop } from '../../../utils/helpers';
import './index.scss';

export function ScrollTopPageButton() {
	const [isVisible, setIsVisible] = useState(false);

	const toggleVisibility = () => {
		if (window.scrollY > window.innerHeight * 0.7) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);
		return () => window.removeEventListener('scroll', toggleVisibility);
	}, []);

	return (
		<button
			type="button"
			className={`scroll-top-page-button ${isVisible ? 'active' : ''}`}
			onClick={scrollToTop}
		>
			<KeyboardArrowUpRoundedIcon className="scroll-top-page-button__icon" />
		</button>
	);
}
