import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import PoweredByIcon from '../../common/PoweredByIcon';

export function SideBarLayout({
	isSideBarOpen,
	isSideMenu,
	setIsSideBarOpen,
	setIsOverlayVisible,
	renderChildren,
	isRenderBrand,
}) {
	const { i18n } = useTranslation();
	const sideBarRef = useRef();

	const handleCloseSideBar = () => {
		setIsOverlayVisible(false);
		setIsSideBarOpen(false);
	};

	useEffect(() => {
		const handleClick = (event) => {
			if (
				sideBarRef.current &&
				!sideBarRef.current.contains(event.target) &&
				isSideBarOpen
			) {
				handleCloseSideBar();
			}
		};

		document.addEventListener('click', handleClick, true);

		return () => {
			document.removeEventListener('click', handleClick, true);
		};
	}, [sideBarRef, isSideBarOpen]);

	return (
		<div className={`side-bar ${isSideBarOpen && 'side-bar--open'}`}>
			<nav ref={sideBarRef} className="side-bar__wrapper">
				{renderChildren(handleCloseSideBar)}
				{isRenderBrand && isSideMenu ? (
					<PoweredByIcon en={i18n.language === 'en'} />
				) : null}
			</nav>
		</div>
	);
}

SideBarLayout.defaultProps = {
	isSideMenu: false,
	isRenderBrand: false,
};

SideBarLayout.propTypes = {
	isSideBarOpen: PropTypes.bool.isRequired,
	isSideMenu: PropTypes.bool,
	isRenderBrand: PropTypes.bool,
	setIsSideBarOpen: PropTypes.func.isRequired,
	setIsOverlayVisible: PropTypes.func.isRequired,
	renderChildren: PropTypes.func.isRequired,
};
