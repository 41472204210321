import { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import './index.scss';

export const DestinationInput = observer(({ destination, setDestination }) => {
	const { t } = useTranslation();

	useEffect(() => {
		return () => setDestination('');
	}, []);

	return (
		<div className="destination-input">
			<p className="destination-input__label">
				{t('delivery.destination-title')}
			</p>
			<TextField
				sx={{ mb: 2 }}
				fullWidth
				id="destination"
				value={destination}
				name="destination"
				multiline
				rows={2}
				inputProps={{ maxLength: 255 }}
				onChange={(value) => setDestination(value.target.value)}
			/>
		</div>
	);
});

DestinationInput.defaultProps = {
	destination: '',
};

DestinationInput.propTypes = {
	destination: PropTypes.string,
	setDestination: PropTypes.func.isRequired,
};
