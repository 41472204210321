import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { jwtDecode } from 'jwt-decode';
import { useParams, useNavigate } from 'react-router-dom';
import { useStores } from '../../stores/context';

const useCustomerLogic = () => {
	const { telegramBotUsername } = useParams();
	const { customerStore, wishlistStore, cartStore, shopsStore } = useStores();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [hasError, setHasError] = useState(false);
	const telegramInitData = window.Telegram.WebApp?.initData;
	const accessToken = localStorage.getItem('accessToken');

	useEffect(() => {
		if (telegramBotUsername) {
			shopsStore
				.getOneByUsername(telegramBotUsername)
				.then(() => {
					sessionStorage.setItem('telegramBotUsername', telegramBotUsername);
				})
				.catch(() => {
					setHasError(true);
				});
		}
	}, [shopsStore, telegramBotUsername]);

	useEffect(() => {
		if (shopsStore.currentShop.language) {
			i18n.changeLanguage(shopsStore.currentShop.language);
		}

		if (customerStore.isLoggedIn && shopsStore.currentShop.id) {
			wishlistStore.getWishlist();
			cartStore.getCart();
		}
	}, [
		customerStore.isLoggedIn,
		shopsStore.currentShop.id,
		shopsStore.currentShop.language,
	]);

	useEffect(() => {
		if (accessToken) {
			const decodedToken = jwtDecode(accessToken);
			customerStore.setCurrentCustomer(decodedToken);
			customerStore.setIsLoggedIn(true);
		} else if (telegramInitData && shopsStore.currentShop.id) {
			customerStore
				.telegramLogin({
					initData: telegramInitData,
					shopId: shopsStore.currentShop.id,
				})
				.catch(() => {
					navigate('/error');
				});
		} else {
			customerStore.setDefaultCustomer();
		}
	}, [telegramInitData, customerStore, shopsStore.currentShop.id, accessToken]);

	return { hasError, t };
};

export default useCustomerLogic;
