/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { PageHeading } from '../../components/shared/PageHeading';
import { NavigateBackButton } from '../../components/shared/NavigateBackButton';
import { useStores } from '../../stores/context';
import PhotosCarousel from '../ProductPage/components/PhotosCarousel';
import { PhoneNumberWrapper } from '../../components/shared/PhoneNumberWrapper';
import './index.scss';

export const ShopAdressesPage = observer(() => {
	const {
		shopsStore: { currentShop },
	} = useStores();
	const { t } = useTranslation();

	return (
		<div className="shop-adresses-page">
			<div className="shop-adresses-page__header">
				<PageHeading headingText={t('shop-adresses-page.title')} />
				<NavigateBackButton />
			</div>
			<ul className="shop-adresses-page__list">
				{currentShop.shopLocations.map((location) => (
					<li key={location.id} className="shop-adresses-page__item">
						{location.images.length > 0 && (
							<PhotosCarousel images={location.images || []} />
						)}
						<dl className="shop-adresses-page__info">
							<div className="shop-adresses-page__info-item">
								<dt className="characteristics__label">
									<span> {t('shop-adresses-page.address')}</span>
								</dt>
								<dd className="characteristics__value">
									<span
										dangerouslySetInnerHTML={{
											__html: DOMPurify.sanitize(location.location),
										}}
									/>
								</dd>
							</div>
							<div className="shop-adresses-page__info-item">
								<dt className="characteristics__label">
									<span>{t('shop-adresses-page.phone')}</span>
								</dt>
								<dd className="characteristics__value">
									{location.telephoneNumbers.map((number) => (
										<PhoneNumberWrapper key={number} phoneNumber={number} />
									))}
								</dd>
							</div>
							<div className="shop-adresses-page__info-item">
								<dt className="characteristics__label">
									<span>{t('shop-adresses-page.schedule')}</span>
								</dt>
								<dd className="characteristics__value">
									<span
										dangerouslySetInnerHTML={{
											__html: DOMPurify.sanitize(location.workSchedule),
										}}
									/>
								</dd>
							</div>
						</dl>
					</li>
				))}
			</ul>
		</div>
	);
});
