import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import { useStores } from '../../../stores/context';
import { PhoneNumberWrapper } from '../PhoneNumberWrapper';
import './index.scss';

export const ContactInformation = observer(() => {
	const {
		shopsStore: { currentShop },
	} = useStores();
	const { t } = useTranslation();

	const getProperIcon = (link) => {
		if (link.includes('instagram')) return <InstagramIcon />;
		if (link.includes('facebook')) return <FacebookRoundedIcon />;
		if (link.includes('whatsapp')) return <WhatsAppIcon />;
		return <LinkOutlinedIcon />;
	};

	return (
		<div className="contact-information">
			<div className="contact-information__wrapper">
				<p className="contact-information__title">
					{t('contact-information.title')}
				</p>
				{currentShop.telephoneNumbers.length > 0 && (
					<div className="contact-information__phones">
						{currentShop.telephoneNumbers.map((number) => (
							<PhoneNumberWrapper key={number} phoneNumber={number} />
						))}
					</div>
				)}
			</div>
			{currentShop.socialNetworkLinks.length > 0 && (
				<div className="contact-information__links">
					{currentShop.socialNetworkLinks
						.filter((item) => item.match(/^(https?):\/\/[^\s/$.?#].[^\s]*$/gm))
						.map((link) => (
							// eslint-disable-next-line jsx-a11y/anchor-is-valid
							<a
								key={link}
								href="#"
								onClick={() => window.open(link, '_blank')}
							>
								<button
									className="contact-information__button"
									aria-label="Viber"
									type="button"
								>
									<span>{getProperIcon(link)}</span>
								</button>
							</a>
						))}
				</div>
			)}
		</div>
	);
});
