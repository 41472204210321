import { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';
import './index.scss';

export function ShopPicker({ shops, shop, setShop }) {
	const { t } = useTranslation();

	useEffect(() => {
		setShop(shops[0]);
	}, []);

	return (
		<div className="shop-picker">
			<p className="shop-picker__title">{t('delivery.shop.choose-shop')}</p>
			<Select
				className="shop-picker__selector"
				value={shop?.location || ''}
				onChange={(event) =>
					setShop(shops.find((item) => item.location === event.target.value))
				}
				sx={{ fontSize: '14px' }}
			>
				{shops.map(({ id, location }) => (
					<MenuItem
						key={id}
						value={location}
						sx={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}
					>
						{location.replace(/<br \/>/g, '')}
					</MenuItem>
				))}
			</Select>
		</div>
	);
}

ShopPicker.propTypes = {
	shops: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
	shop: PropTypes.instanceOf(Object).isRequired,
	setShop: PropTypes.func.isRequired,
};
