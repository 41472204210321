import { types } from 'mobx-state-tree';

const Value = types.model('Value', {
	id: types.maybeNull(types.identifierNumber),
	value: types.maybeNull(types.string),
});

export const Attribute = types.model('Attribute', {
	id: types.maybeNull(types.identifierNumber),
	name: types.maybeNull(types.string),
	type: types.maybeNull(types.string),
	isVariant: types.maybeNull(types.boolean),
	values: types.maybeNull(types.array(Value)),
});

const ProductAttribute = types.model('ProductAttribute', {
	id: types.maybeNull(types.identifierNumber),
	productId: types.maybeNull(types.number),
	attribute: types.optional(Attribute, {}),
	value: types.maybeNull(Value),
});

export default ProductAttribute;
