export const calculateTotalPrice = (products) => {
	const totalPrice = products.reduce(
		(sum, { price, discountPrice, quantity }) =>
			sum + (discountPrice || price) * quantity,
		0,
	);
	return totalPrice;
};

export const phoneValidationRules = {
	ua: (value) => value.length !== 12 || value.substring(0, 3) !== '380',
	kz: (value) => value.length !== 11 || value.substring(0, 1) !== '7',
	pl: (value) => value.length !== 11 || value.substring(0, 2) !== '48',
};

export const formatPrice = (price) =>
	price.toLocaleString('en-US').replace(',', ' ');

export const formatTelegramBotUsername = (username) =>
	username.startsWith('@') ? username.substring(1) : username;

export const capitalizeFirstLetter = (string) =>
	string?.length ? string.charAt(0).toUpperCase() + string.slice(1) : '';

export const cartCounterFormater = (itemsLength) =>
	itemsLength < 100 ? itemsLength : '99+';

export const categoryTitleFormater = (title, translateFunction) =>
	title === 'default' ? translateFunction('categories-page.default') : title;

export const countTotalProducts = (products) =>
	products.reduce((accumulator, product) => accumulator + product.quantity, 0);

export const validateEmail = (email) => {
	return email.match(
		/* eslint-disable */
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		/* eslint-enable */
	);
};

export const formatProductTitle = ({ title, attributes }) => {
	if (!title) return '';
	if (attributes) {
		const colorAttribute = attributes.find(
			({ attribute }) => attribute.type === 'color',
		);
		const updatedAttributes = attributes.filter(
			({ attribute }) => attribute.isVariant && attribute.type !== 'color',
		);

		if (colorAttribute) {
			updatedAttributes.unshift(colorAttribute);
		}

		return `${capitalizeFirstLetter(title)} ${updatedAttributes
			.map(({ value }) => (value?.value ? value.value.split(',')[0] : ''))
			.join(' ')}`;
	}

	return capitalizeFirstLetter(title);
};

export const getValueByPath = (object, path) => {
	return path
		.split('.')
		.reduce(
			(obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
			object,
		);
};

export const sortArrayBy = (attributes, accessor) => {
	return attributes.sort((a, b) => {
		const aValue = getValueByPath(a, accessor);
		const bValue = getValueByPath(b, accessor);
		return String(aValue).localeCompare(String(bValue));
	});
};

export const scrollToTop = () => {
	const c = document.documentElement.scrollTop || document.body.scrollTop;
	if (c > 0) {
		window.requestAnimationFrame(scrollToTop);
		window.scrollTo(0, c - c / 10);
		if (c < 1) {
			window.scrollTo(0, 0);
		}
	}
};

export const getGoogleAnalyticsScript = (googleAnalyticsId) => `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${googleAnalyticsId}');
`;

export const getFacebookPixelScript = (facebookPixelId) => `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window,document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${facebookPixelId}');
  fbq('track', 'PageView');
`;
