import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem('accessToken');
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => Promise.reject(error),
);

const setupInterceptors = (store) => {
	instance.interceptors.response.use(
		(response) => response,
		(error) => {
			if (
				error.response &&
				error.response.status === 401 &&
				store.shopsStore.currentShop?.telegramShopBotUsername
			) {
				localStorage.removeItem('accessToken');
				store.customerStore.setDefaultCustomer();
				store.customerStore.setIsLoggedIn(false);
				window.location.href = `/s/${store.shopsStore.currentShop.telegramShopBotUsername}`;
			}
			return Promise.reject(error);
		},
	);
};

const api = {
	products: {
		getAll: (params) => instance.get('/products', { params }),
		getOneById: (productId) => instance.get(`/products/${productId}`),
		findParentIfExist: (productId) =>
			instance.get(`/products/findParentIfExist/${productId}`),
		searchByTitle: (params) => instance.get('/products/search', { params }),
		getByIds: (productIds) => instance.post('/products/ids', { productIds }),
	},
	shippingAddress: {
		create: (payload) => instance.post('/shipping-addresses', payload),
	},
	shops: {
		getOneByUsername: (telegramShopBotUsername) =>
			instance.get(`/shops/username/${telegramShopBotUsername}`),
	},
	customers: {
		create: (payload) => instance.post('/customers', payload),
	},
	orders: {
		create: (payload) => instance.post('/orders', payload),
		getByCustomer: (shopId, customerId, limit) => {
			let url = `/orders/byCustomer/${shopId}/${customerId}`;
			if (limit) {
				url += `/${limit}`;
			}
			return instance.get(url);
		},
	},
	wishlists: {
		find: (payload) => instance.post('/wishlists', payload),
		update: (wishlistId, params) =>
			instance.patch(`/wishlists/${wishlistId}`, params),
	},
	carts: {
		find: (payload) => instance.post('/carts', payload),
		update: (cartId, params) => instance.patch(`/carts/${cartId}`, params),
	},
	attributes: {
		find: (shopId) => instance.get(`/attributes/${shopId}`),
		findByCategory: (categoryId, shopId) =>
			instance.get(`/categories/${categoryId}/attributes/${shopId}`),
	},
	novaPoshta: {
		getSettlements: (searchQuery) =>
			instance.post('/nova-poshta/settlements', { searchQuery }),
		getStreets: (searchQuery, settlementRef) =>
			instance.post('/nova-poshta/streets', { searchQuery, settlementRef }),
		getWarehouses: (cityRef) =>
			instance.post('/nova-poshta/warehouses', { cityRef }),
	},
	auth: {
		generateTelegramAuthLink: (payload) =>
			instance.post('/auth/generateTelegramAuthLink', payload),
		checkTelegramAuthorization: (payload) =>
			instance.post('/auth/checkTelegramAuthorization', payload),
		telegramLogin: (payload) => instance.post('/auth/telegramLogin', payload),
	},
};

export { setupInterceptors };
export default api;
