import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getGoogleAnalyticsScript, getFacebookPixelScript } from './helpers';

const useInjectScript = ({ googleAnalyticsId, facebookPixelId }) => {
	useEffect(() => {
		if (googleAnalyticsId) {
			const gtagScriptSrc = document.createElement('script');
			gtagScriptSrc.async = true;
			gtagScriptSrc.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
			document.head.appendChild(gtagScriptSrc);

			const gtagScript = document.createElement('script');
			gtagScript.innerHTML = getGoogleAnalyticsScript(googleAnalyticsId);
			document.head.appendChild(gtagScript);
		}

		if (facebookPixelId) {
			const fbPixelScript = document.createElement('script');
			fbPixelScript.innerHTML = getFacebookPixelScript(facebookPixelId);
			document.head.appendChild(fbPixelScript);

			const fbPixelNoscript = document.createElement('noscript');
			fbPixelNoscript.innerHTML = `
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1" />
      `;
			document.body.appendChild(fbPixelNoscript);
		}
	}, [googleAnalyticsId, facebookPixelId]);
};

useInjectScript.propTypes = {
	googleAnalyticsId: PropTypes.string,
	facebookPixelId: PropTypes.string,
};

export default useInjectScript;
