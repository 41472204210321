import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { observer } from 'mobx-react';
import { BlockTitle } from '../BlockTitle';
import { CheckoutTile } from '../CheckoutTile';
import { calculateTotalPrice, formatPrice } from '../../../../utils/helpers';
import './index.scss';

export const ProductsPreview = observer(({ products, currencySymbol }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { telegramBotUsername } = useParams();

	const price = calculateTotalPrice(products);

	return (
		<div className="products-preview">
			<div className="products-preview__header">
				<h1 className="products-preview__title">
					{t('checkout-page.order.title')}
				</h1>
				<p className="products-preview__price">
					{`${t('checkout-page.order.price')}: ${formatPrice(
						price,
					)} ${currencySymbol}`}
				</p>
			</div>
			<BlockTitle
				number={1}
				title={t('checkout-page.block-titles.products')}
				action={() => navigate(`/s/${telegramBotUsername}/cart`)}
				actionIcon={<BorderColorIcon />}
			/>
			{products.map((product) => (
				<CheckoutTile
					item={product}
					currencySymbol={currencySymbol}
					key={product.id}
				/>
			))}
		</div>
	);
});

ProductsPreview.propTypes = {
	products: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
	currencySymbol: PropTypes.string.isRequired,
};
