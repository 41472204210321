import PropTypes from 'prop-types';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import './index.scss';

export function HeaderFilter({
	headerText,
	goBackAction,
	resetButtonText,
	resetFilters,
}) {
	return (
		<div className="header-filter">
			<button
				className="header-filter__back-button"
				aria-label="Go back"
				type="button"
				onClick={goBackAction}
			>
				<KeyboardArrowLeftRoundedIcon />
				{headerText}
			</button>
			<button
				className="header-filter__reset-button"
				aria-label="Reset filters"
				type="button"
				onClick={resetFilters}
			>
				{resetButtonText}
			</button>
		</div>
	);
}

HeaderFilter.propTypes = {
	headerText: PropTypes.string.isRequired,
	goBackAction: PropTypes.func.isRequired,
	resetButtonText: PropTypes.string.isRequired,
	resetFilters: PropTypes.func.isRequired,
};
