import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../../utils/helpers';
import './index.scss';

export function ProductColorOptions({
	colors,
	title,
	selectedColor,
	setSelectedColor,
	activeOptions,
}) {
	const { t } = useTranslation();

	return (
		<div className="color-options">
			<p className="color-options__label">
				<span> {t('color-options.color')}</span>:&nbsp;
				{capitalizeFirstLetter(selectedColor?.value?.value?.split(',')[0])}
			</p>
			<div className="color-options__wrapper">
				<ul className="color-options__list">
					{colors.map((color) => (
						<li className="color-options__item" key={color.value.value}>
							<button
								className={`color-options__preview ${
									color.value.value === selectedColor?.value?.value &&
									'color-options__preview--active'
								} ${
									!activeOptions.includes(color.value.id) &&
									'color-options__disabled'
								}`}
								aria-label="Color preview"
								type="button"
								onClick={() => setSelectedColor(title, color)}
							>
								<span
									style={{
										backgroundColor: color.value.value.split(',')[1],
									}}
								/>
							</button>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

ProductColorOptions.propTypes = {
	colors: PropTypes.instanceOf(Array).isRequired,
	title: PropTypes.string.isRequired,
	selectedColor: PropTypes.instanceOf(Object).isRequired,
	activeOptions: PropTypes.instanceOf(Array).isRequired,
	setSelectedColor: PropTypes.func.isRequired,
};
