import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { alpha } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import './index.scss';

function AuthModal({ open, handleClose }) {
	const { t } = useTranslation();
	const { telegramBotUsername } = useParams();

	const handleLoginViaTelegram = () => {
		const telegramUrl = `https://t.me/${telegramBotUsername}?start=auth`;
		window.open(telegramUrl, '_blank');
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="auth-modal-title"
			aria-describedby="auth-modal-description"
			className="auth-modal"
		>
			<Box className="auth-modal__content">
				<Typography id="auth-modal-title" variant="h6" component="h2">
					{t('authModal.title')}
				</Typography>
				<Typography id="auth-modal-description" sx={{ mt: 2 }}>
					{t('authModal.description')}
				</Typography>
				<Button
					variant="contained"
					className="auth-modal__button"
					onClick={handleLoginViaTelegram}
					sx={{
						mt: 2,
						backgroundColor: '#7b61ff',
						'&:hover': {
							backgroundColor: alpha('#7b61ff', 0.8),
						},
					}}
				>
					{t('authModal.button_login_via_telegram')}
				</Button>
			</Box>
		</Modal>
	);
}

AuthModal.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
};

export default AuthModal;
