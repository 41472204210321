import { flow, getEnv, getParent } from 'mobx-state-tree';

const ShopsStoreActions = (self) => ({
	getOneById: flow(function* getOneById(shopId) {
		const { api } = getEnv(self);
		const { categoriesStore, attributesStore } = getParent(self);
		try {
			const { data } = yield api.shops.getOneById(shopId);

			categoriesStore.setCategories(data.categories);

			self.currentShop = {
				...data,
				paymentTypes: data?.parameters?.paymentMethodTypes,
				deliveryTypes: data?.parameters?.deliveryMethodTypes,
				scripts: data?.parameters?.scripts,
				paymentMethods:
					data?.onlinePaymentMethods?.map((item) => item.paymentMethod) || [],
			};

			attributesStore.getAttributes();
		} catch (error) {
			console.error(error);
		}
	}),
	getOneByUsername: flow(function* getOneByUsername(telegramShopBotUsername) {
		const { api } = getEnv(self);
		const { categoriesStore, attributesStore } = getParent(self);
		try {
			const { data } = yield api.shops.getOneByUsername(
				telegramShopBotUsername,
			);

			if (!data) {
				throw new Error('Shop not found');
			}

			categoriesStore.setCategories(data.categories);

			self.currentShop = {
				...data,
				paymentTypes: data?.parameters?.paymentMethodTypes,
				deliveryTypes: data?.parameters?.deliveryMethodTypes,
				scripts: data?.parameters?.scripts,
				paymentMethods:
					data?.onlinePaymentMethods?.map((item) => item.paymentMethod) || [],
			};

			attributesStore.getAttributes();
		} catch (error) {
			self.error = error.message;
			window.location.replace('/error');
			throw error;
		}
	}),

	createShippingAddress: async (payload) => {
		const { api } = getEnv(self);
		try {
			const shippingAddress = await api.shippingAddress.create(payload);
			return shippingAddress.data.raw[0];
		} catch (error) {
			return error;
		}
	},
	createOrder: flow(function* createOrder(payload) {
		const { api } = getEnv(self);
		try {
			yield api.orders.create(payload);
		} catch (error) {
			console.error(error);
		}
	}),
});

export default ShopsStoreActions;
