import { types } from 'mobx-state-tree';

export const Settlement = types.model('Settlement', {
	Present: types.maybeNull(types.string),
	MainDescription: types.maybeNull(types.string),
	Area: types.maybeNull(types.string),
	SettlementTypeCode: types.maybeNull(types.string),
	Ref: types.maybeNull(types.string),
	DeliveryCity: types.maybeNull(types.string),
	ParentRegionCode: types.maybeNull(types.string),
});

export const Warehouse = types.model('Warehouse', {
	Description: types.maybeNull(types.string),
	ShortAddress: types.maybeNull(types.string),
	Phone: types.maybeNull(types.string),
	Ref: types.maybeNull(types.string),
	Number: types.maybeNull(types.string),
});

export const Street = types.model('Street', {
	SettlementStreetRef: types.maybeNull(types.string),
	Present: types.maybeNull(types.string),
});
