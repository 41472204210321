import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import { useStores } from '../../../../stores/context';
import { ShopPicker } from '../ShopPicker';
import { Settlement } from '../Settlement';
import { SelectedContainer } from '../SelectedContainer';
import { WarehousePicker } from '../WarehousePicker';
import { AddressPicker } from '../AddressPicker';
import { DestinationInput } from '../DestinationInput';
import './index.scss';

export const Pickup = observer(
	({
		settlement,
		setSettlement,
		shopLocation,
		setShopLocation,
		deliveryType,
		setDeliveryType,
		destination,
		setDestination,
		warehouse,
		setWarehouse,
		address,
		setAddress,
		getError,
		validate,
		errors,
	}) => {
		const { t } = useTranslation();
		const { shopsStore, novaPoshtaStore } = useStores();
		const [isNovaPoshtaGroup, setIsNovaPoshtaGroup] = useState(false);

		const { shopLocations, deliveryTypes } = toJS(shopsStore.currentShop);
		const { warehouses } = toJS(novaPoshtaStore);

		const novaPoshtaServices = [
			{
				type: 'novaPoshtaSelfPickup',
				title: t('delivery.nova-poshta.self-pickup'),
				enabled: deliveryTypes.includes('novaPoshtaSelfPickup'),
				component: (value) => (
					<WarehousePicker
						warehouses={warehouses}
						warehouse={warehouse}
						setWarehouse={setWarehouse}
						deliveryCity={value.DeliveryCity}
					/>
				),
			},
			{
				type: 'novaPoshtaCourier',
				title: t('delivery.nova-poshta.courier'),
				enabled: deliveryTypes.includes('novaPoshtaCourier'),
				component: (value) => (
					<AddressPicker
						address={address}
						setAddress={setAddress}
						getError={getError}
						validate={validate}
						errors={errors}
						settlementRef={value.Ref}
					/>
				),
			},
		];

		const handleChangePickupType = (value) => {
			if (value !== deliveryType) {
				if (!['novaPoshtaSelfPickup', 'novaPoshtaCourier'].includes(value)) {
					setSettlement({});
					setIsNovaPoshtaGroup(false);
				} else {
					setIsNovaPoshtaGroup(true);
				}
				setWarehouse({});
				setShopLocation({});
				setDestination('');
				setAddress({
					street: '',
					building: '',
					entrance: '',
					apartment: '',
				});
				setDeliveryType(value);
			}
		};

		return (
			<div className="pickup">
				{deliveryTypes.includes('selfPickup') && (
					<SelectedContainer
						title={t('delivery.types.self-pickup')}
						active={deliveryType === 'selfPickup'}
						withBorder
						onClick={() => handleChangePickupType('selfPickup')}
						item={
							shopLocations.length ? (
								<ShopPicker
									shops={shopLocations}
									shop={shopLocation}
									setShop={setShopLocation}
								/>
							) : null
						}
					/>
				)}
				{deliveryTypes.includes('delivery') && (
					<SelectedContainer
						title={t('delivery.types.delivery')}
						active={deliveryType === 'delivery'}
						withBorder
						onClick={() => handleChangePickupType('delivery')}
						item={
							<DestinationInput
								destination={destination}
								setDestination={setDestination}
							/>
						}
					/>
				)}
				{['novaPoshtaSelfPickup', 'novaPoshtaCourier'].some((value) =>
					deliveryTypes.includes(value),
				) && (
					<SelectedContainer
						title={t('delivery.types.nova-poshta')}
						active={isNovaPoshtaGroup}
						withBorder
						onClick={() => {
							if (
								!['novaPoshtaSelfPickup', 'novaPoshtaCourier'].includes(
									deliveryType,
								)
							) {
								handleChangePickupType('novaPoshtaSelfPickup');
							}
						}}
						item={
							<>
								<Settlement
									setSettlement={setSettlement}
									settlement={settlement}
								/>
								{settlement.DeliveryCity &&
									novaPoshtaServices.map(
										(item) =>
											item.enabled && (
												<SelectedContainer
													key={item.type}
													title={item.title}
													active={deliveryType === item.type}
													withBorder
													onClick={() => handleChangePickupType(item.type)}
													item={item.component(settlement)}
												/>
											),
									)}
							</>
						}
					/>
				)}
				{deliveryTypes.includes('electronicDelivery') && (
					<SelectedContainer
						title={t('delivery.types.electronic-delivery')}
						active={deliveryType === 'electronicDelivery'}
						withBorder
						onClick={() => handleChangePickupType('electronicDelivery')}
						item={
							<DestinationInput
								destination={destination}
								setDestination={setDestination}
							/>
						}
					/>
				)}
			</div>
		);
	},
);

Pickup.defaultProps = {
	destination: '',
};

Pickup.propTypes = {
	settlement: PropTypes.instanceOf(Object).isRequired,
	setSettlement: PropTypes.func.isRequired,
	shopLocation: PropTypes.instanceOf(Object).isRequired,
	setShopLocation: PropTypes.func.isRequired,
	deliveryType: PropTypes.string.isRequired,
	setDeliveryType: PropTypes.func.isRequired,
	destination: PropTypes.string,
	setDestination: PropTypes.func.isRequired,
	warehouse: PropTypes.instanceOf(Object).isRequired,
	setWarehouse: PropTypes.func.isRequired,
	address: PropTypes.instanceOf(Object).isRequired,
	setAddress: PropTypes.func.isRequired,
	getError: PropTypes.func.isRequired,
	validate: PropTypes.func.isRequired,
	errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};
