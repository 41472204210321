/* eslint-disable react/no-array-index-key */
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useStores } from '../../../../stores/context';
import { ProductTile } from '../../components/ProductTile';
import { Loader } from '../../../../components/common/Loader';

export const ProductsGrid = observer(
	({ products, fromCategory, shopFormatTitle, currencySymbol }) => {
		const {
			cartStore,
			productsStore: { loading: isLoading },
			appStore: { productsSwitchState },
		} = useStores();

		const handleIsProductInCart = useCallback(
			(item) => cartStore.checkIsProductInCart(item.id),
			[toJS(cartStore.currentCart.cart)],
		);

		const renderLoader = () => {
			return Array.from({ length: 16 }).map((_, index) => (
				<Grid
					item
					xs={productsSwitchState === 6 ? 6 : 12}
					sm={productsSwitchState === 6 ? 6 : 12}
					md={productsSwitchState === 6 ? 4 : 12}
					lg={productsSwitchState === 6 ? 3 : 12}
					key={`loader-${index}`}
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<Loader
						type={
							productsSwitchState === 6
								? 'skeleton-product-full'
								: 'skeleton-product-short'
						}
						enabled={isLoading}
					/>
				</Grid>
			));
		};

		return (
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2} minHeight={160}>
					{isLoading
						? renderLoader()
						: products.map((product) => (
								<Grid
									item
									xs={productsSwitchState === 6 ? 6 : 12}
									sm={productsSwitchState === 6 ? 6 : 12}
									md={productsSwitchState === 6 ? 4 : 12}
									lg={productsSwitchState === 6 ? 3 : 12}
									key={product.id}
									display="flex"
									justifyContent="center"
									alignItems="center"
								>
									<ProductTile
										item={product}
										currencySymbol={currencySymbol}
										shopFormatTitle={shopFormatTitle}
										fromCategory={fromCategory}
										tileType={productsSwitchState === 6 ? 'tile' : 'row'}
										isProductInCart={handleIsProductInCart(product)}
									/>
								</Grid>
						  ))}
				</Grid>
			</Box>
		);
	},
);

ProductsGrid.propTypes = {
	products: PropTypes.instanceOf(Array).isRequired,
	fromCategory: PropTypes.bool.isRequired,
	shopFormatTitle: PropTypes.bool.isRequired,
	currencySymbol: PropTypes.string.isRequired,
};
