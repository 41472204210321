import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = ['uk'];
export const availableLanguages = ['en', 'uk', 'pl', 'kz', 'ru'];

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng,
		detection: {
			checkWhitelist: true,
		},
		debug: false,
		whitelist: availableLanguages,
	});

export default i18n;
