const CheckoutStoreActions = (self) => ({
	setUserInfo: (value) => {
		self.userInfo = value;
	},
	setRecipientInfo: (value) => {
		self.recipientInfo = value;
	},
	setAmIRecipient: (value) => {
		self.amIRecipient = value;
	},
	setComment: (value) => {
		self.comment = value;
	},
});

export default CheckoutStoreActions;
