import { PropTypes } from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatPrice, formatProductTitle } from '../../../../utils/helpers';
import './index.scss';

export function CheckoutTile({ item, currencySymbol }) {
	const { t } = useTranslation();
	const { telegramBotUsername } = useParams();

	const getTotalPrice = () => {
		if (item.discountPrice)
			return formatPrice(item.discountPrice * item.quantity);
		return formatPrice(item.price * item.quantity);
	};

	return (
		<Link
			to={`/s/${telegramBotUsername}/${item?.id}`}
			state={{ variantParentId: item?.variantParentId }}
			className="checkout-tile"
		>
			<div className="checkout-tile__title">
				<figure className="checkout-tile__figure">
					<span className="checkout-tile__picture">
						{item?.image ? (
							<img src={item?.image} alt="img" />
						) : (
							<div className="checkout-tile__skeleton" />
						)}
					</span>
					<figcaption>{formatProductTitle(item)}</figcaption>
				</figure>
				<dl className="checkout-tile__options">
					<div className="checkout-tile__option checkout-tile__option--price">
						<dt className="checkout-tile__label">
							{t('checkout-tile.fields.price')}
						</dt>
						<dd className="checkout-tile__digit">
							<div
								className={`checkout-tile__price ${
									Boolean(item.discountPrice) && 'checkout-tile__discount'
								}`}
							>
								{`${formatPrice(
									item.discountPrice || item.price,
								)} ${currencySymbol}`}
							</div>
							{item.discountPrice ? (
								<div className="checkout-tile__price--old">{`${formatPrice(
									item.price,
								)} ${currencySymbol}`}</div>
							) : null}
						</dd>
					</div>
					<div className="checkout-tile__option checkout-tile__option--quantity">
						<dt className="checkout-tile__label">
							{t('checkout-tile.fields.quantity')}
						</dt>
						<dd className="checkout-tile__digit">{item.quantity}</dd>
					</div>
					<div className="checkout-tile__option checkout-tile__option--amount">
						<dt className="checkout-tile__label">
							{t('checkout-tile.fields.total')}
						</dt>
						<dd className="checkout-tile__digit">{`${getTotalPrice()} ${currencySymbol}`}</dd>
					</div>
				</dl>
			</div>
		</Link>
	);
}

CheckoutTile.propTypes = {
	item: PropTypes.instanceOf(Object).isRequired,
	currencySymbol: PropTypes.string.isRequired,
};
