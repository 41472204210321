import { React } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

export function PageHeading({ headingText, margin }) {
	return (
		<h1 className="page-heading" style={{ margin }}>
			{headingText}
		</h1>
	);
}

PageHeading.defaultProps = {
	margin: '',
};

PageHeading.propTypes = {
	headingText: PropTypes.string.isRequired,
	margin: PropTypes.string,
};
