import { flow, getEnv } from 'mobx-state-tree';

const NovaPoshtaStoreActions = (self) => ({
	toggleLoading: (value) => {
		self.loading = value;
	},
	getSettlements: flow(function* getSettlements(searchQuery) {
		const { api } = getEnv(self);
		try {
			self.toggleLoading(true);
			const { data } = yield api.novaPoshta.getSettlements(searchQuery);
			self.settlements = data || [];
			self.toggleLoading(false);
		} catch (e) {
			console.error(e);
			self.toggleLoading(false);
		}
	}),
	getWarehouses: flow(function* getWarehouses(cityRef) {
		const { api } = getEnv(self);
		try {
			self.toggleLoading(true);
			const { data } = yield api.novaPoshta.getWarehouses(cityRef);
			if (data?.length) self.warehouses = data;
			self.toggleLoading(false);
		} catch (e) {
			console.error(e);
			self.toggleLoading(false);
		}
	}),
	resetWarehouses: () => {
		self.warehouses = [];
	},
	getStreets: flow(function* getStreets(searchQuery, settlementRef) {
		const { api } = getEnv(self);
		try {
			self.toggleLoading(true);
			const { data } = yield api.novaPoshta.getStreets(
				searchQuery,
				settlementRef,
			);
			self.streets = data || [];
			self.toggleLoading(false);
		} catch (e) {
			console.error(e);
			self.toggleLoading(false);
		}
	}),
});

export default NovaPoshtaStoreActions;
