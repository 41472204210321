import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
	calculateTotalPrice,
	countTotalProducts,
	formatPrice,
} from '../../../../utils/helpers';
import './index.scss';

export function InTotal({
	products,
	action,
	hasErrors,
	disableButton,
	currencySymbol,
}) {
	const { t } = useTranslation();

	const price = calculateTotalPrice(products);
	const totalProducts = countTotalProducts(products);

	return (
		<div className="in-total">
			<h1 className="in-total__title">{t('checkout-page.in-total.title')}</h1>
			<div className="in-total__info">
				<p className="in-total__info-label">
					{`${totalProducts} ${t('checkout-page.in-total.info')}`}
				</p>
				<p className="in-total__info-value">{`${formatPrice(
					price,
				)} ${currencySymbol}`}</p>
			</div>
			<div className="in-total__info">
				<p className="in-total__info-label">
					{t('checkout-page.in-total.total')}
				</p>
				<p className="in-total__info-value total">
					{`${formatPrice(price)} ${currencySymbol}`}
				</p>
			</div>
			<button
				className="in-total__action-button"
				type="button"
				disabled={disableButton || !products.length || hasErrors}
				aria-label="Confirm the order"
				onClick={action}
			>
				{t('checkout-page.in-total.button')}
			</button>
		</div>
	);
}

InTotal.defaultProps = {
	disableButton: false,
};

InTotal.propTypes = {
	products: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
	disableButton: PropTypes.bool,
	action: PropTypes.func.isRequired,
	hasErrors: PropTypes.bool.isRequired,
	currencySymbol: PropTypes.string.isRequired,
};
