/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PropTypes } from 'prop-types';
import './index.scss';

export function SelectedContainer({
	item,
	active,
	title,
	withBorder,
	onClick,
}) {
	return (
		<div
			className={`selected-container ${
				active && withBorder && item ? 'selected-container__active' : ''
			}`}
			onClick={onClick}
		>
			<div className="selected-container__header">
				<input
					className="selected-container__radio"
					type="radio"
					checked={active}
					readOnly
				/>
				<p className="selected-container__title">{title}</p>
			</div>
			{active && item}
		</div>
	);
}

SelectedContainer.defaultProps = {
	item: null,
	active: false,
	withBorder: false,
};

SelectedContainer.propTypes = {
	item: PropTypes.element,
	active: PropTypes.bool,
	withBorder: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
};
