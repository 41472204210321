import { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded';
import { useStores } from '../../stores/context';
import {
	calculateTotalPrice,
	countTotalProducts,
	formatPrice,
} from '../../utils/helpers';
import loaderGif from '../../assets/gifs/loaderGif.gif';
import { PageHeading } from '../../components/shared/PageHeading';
import { NavigateBackButton } from '../../components/shared/NavigateBackButton';
import { ModalPopUp } from '../../components/shared/ModalPopUp';
import { CartTile } from './components/CartTile';
import './index.scss';

export const CartPage = observer(() => {
	const { telegramBotUsername } = useParams();
	const {
		cartStore: {
			products: productsObsr,
			currentCart,
			getCartItemsByIds,
			removeFromCart,
			clearCart,
			updateQuantity,
			loading,
		},
		shopsStore: {
			currentShop: { currencySymbol },
		},
	} = useStores();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const products = toJS(productsObsr);
	const totalPrice = calculateTotalPrice(products);
	const totalProducts = countTotalProducts(products);

	const [itemToRemove, setItemToRemove] = useState({ id: '', title: '' });

	const handleRemoveItem = () => {
		removeFromCart(itemToRemove.id);
		setItemToRemove({ id: '', title: '' });
	};

	const handleUpdateItemQuantity = (id, quantity) => {
		updateQuantity(id, quantity);
	};

	const handleClearCart = () => {
		clearCart();
		navigate(`/s/${telegramBotUsername}`);
	};

	useEffect(() => {
		if (currentCart.cart.length !== 0) {
			getCartItemsByIds();
		}
	}, [currentCart.cart.length]);

	const renderCartContent = () => {
		if (products.length)
			return products.map((product) => (
				<CartTile
					item={product}
					key={product.id}
					removeProduct={setItemToRemove}
					currencySymbol={currencySymbol}
					updateQuantity={handleUpdateItemQuantity}
				/>
			));

		return (
			<div className="cart-page__empty">
				<AddShoppingCartRoundedIcon />
				<h4>{t('cart-page.empty')}</h4>
				<Link to={`/s/${telegramBotUsername}`}>
					<p>{t('cart-page.to-products')}</p>
				</Link>
			</div>
		);
	};

	return (
		<div className="cart-page">
			<div className="cart-page__header">
				{itemToRemove.id && (
					<ModalPopUp
						title={t('cart-page.remove.title')}
						message={`${t('cart-page.remove.message')} ${
							itemToRemove.title
						} ${t('cart-page.remove.from')}?`}
						action={handleRemoveItem}
						actionTitle={t('modal.delete-button')}
						onClose={() => setItemToRemove({ id: '', title: '' })}
					/>
				)}
				{products.length > 0 ? (
					<>
						<div className="cart-page__header-wrapper">
							<NavigateBackButton />
							<PageHeading headingText={t('cart-page.title')} />
						</div>
						<button
							className="cart-page__remove-all-button"
							aria-label="Remove all"
							type="button"
							onClick={handleClearCart}
						>
							{t('cart-page.remove-all-button')}
						</button>
					</>
				) : (
					<>
						<PageHeading headingText={t('cart-page.title')} />
						<NavigateBackButton />
					</>
				)}
			</div>
			<div className={`cart-page__products ${!products.length && 'center'}`}>
				{loading ? (
					<div className="image-loader">
						<img
							src={loaderGif}
							alt="Loading..."
							style={{ width: '100px', height: '100px', marginTop: '100px' }}
						/>
					</div>
				) : (
					renderCartContent()
				)}
			</div>
			{products.length > 0 && (
				<div className="cart-page__footer-container">
					<div className="cart-page__footer">
						<div className="cart-page__total">
							<p>{`${t('cart-page.total')}: ${totalProducts}`}</p>
							<p>{`${formatPrice(totalPrice)} ${currencySymbol}`}</p>
						</div>
						<Link
							to={`/s/${telegramBotUsername}/checkout`}
							className="cart-page__checkout-button"
						>
							{t('cart-page.checkout-button')}
						</Link>
					</div>
				</div>
			)}
		</div>
	);
});
