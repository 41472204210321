import { useCallback } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useStores } from '../../../../../../stores/context';
import { sortArrayBy } from '../../../../../../utils/helpers';
import './index.scss';

export const OtherFilter = observer(({ name }) => {
	const { productsStore, attributesStore } = useStores();
	const productsStoreJS = toJS(productsStore);

	const attribute = attributesStore.attributes.find(
		(item) => item.name === name,
	);

	const handleCheckBoxChange = useCallback(
		(attributeKey, value) => {
			const values =
				productsStoreJS.searchQuery.attributes.get(attributeKey) || [];

			if (values.includes(value)) {
				productsStore.setSearchQuery({
					attributes: {
						...Object.fromEntries(
							productsStoreJS.searchQuery.attributes.entries(),
						),
						[attributeKey]: values.filter((item) => item !== value),
					},
					page: 1,
				});
			} else {
				productsStore.setSearchQuery({
					attributes: {
						...Object.fromEntries(
							productsStoreJS.searchQuery.attributes.entries(),
						),
						[attributeKey]: [...values, value],
					},
					page: 1,
				});
			}
		},
		[productsStoreJS],
	);

	return (
		<div className="other-filters">
			<ul className="other-filters__checkbox">
				<FormGroup>
					<div className="other-filters__attributes">
						{sortArrayBy(toJS(attribute.values), 'value').map((value) => (
							<FormControlLabel
								key={value.id}
								sx={{
									marginLeft: '5px',
								}}
								control={
									<Checkbox
										checked={(
											productsStoreJS.searchQuery.attributes.get(
												attribute.id.toString(),
											) || []
										).includes(value.id)}
										sx={{
											'&.Mui-checked': {
												color: 'var(--link-color)',
											},
										}}
									/>
								}
								label={
									<Typography
										sx={{
											fontSize: 14,
											color: 'var(--global-black)',
											textTransform: 'capitalize',
										}}
									>
										{value.value.split(',#')[0]}
									</Typography>
								}
								onChange={() =>
									handleCheckBoxChange(attribute.id.toString(), value.id)
								}
							/>
						))}
					</div>
				</FormGroup>
			</ul>
		</div>
	);
});

OtherFilter.propTypes = {
	name: PropTypes.string.isRequired,
};
