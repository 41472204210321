import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, IconButton } from '@mui/material';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ChangeCircleRoundedIcon from '@mui/icons-material/ChangeCircleRounded';
import { useStores } from '../../../../stores/context';
import { InputAutocomplete } from '../../../../components/shared/InputAutocomplete';
import './index.scss';

export const WarehousePicker = observer(
	({ warehouses, warehouse, setWarehouse, deliveryCity }) => {
		const { t } = useTranslation();
		const {
			novaPoshtaStore: { loading, getWarehouses },
		} = useStores();
		const [filteredWarehouses, setFilteredWarehouses] = useState([]);

		const handleUpdateValue = (value) => {
			setWarehouse(warehouses.find((item) => item.Description === value));
			setFilteredWarehouses(warehouses);
		};

		const handleUpdateFilteredWarehouse = (value) => {
			setFilteredWarehouses(
				warehouses.filter((item) => item.Description.includes(value)),
			);
		};

		const handleLoadWarehouses = () => {
			getWarehouses(deliveryCity);
		};

		useEffect(() => {
			if (deliveryCity) handleLoadWarehouses();
		}, [deliveryCity]);

		useEffect(() => {
			setWarehouse({});
			setFilteredWarehouses(warehouses);

			return () => setWarehouse({});
		}, [warehouses[0]?.Description]);

		return (
			<div className="warehouse-picker">
				<div className="warehouse-picker__warehouse">
					{loading ? (
						<CircularProgress
							size={24}
							sx={{ color: 'var(--global-black-60)' }}
						/>
					) : (
						<WarehouseIcon />
					)}
					<p className="warehouse-picker__title">
						{warehouse?.Description ||
							t('delivery.nova-poshta.choose-warehouse')}
					</p>
					{!loading && !filteredWarehouses.length && (
						<IconButton
							onClick={handleLoadWarehouses}
							sx={{ width: '24px', height: '24px', marginLeft: '5px' }}
						>
							<ChangeCircleRoundedIcon />
						</IconButton>
					)}
				</div>
				<InputAutocomplete
					value=""
					disabled={loading || !filteredWarehouses.length}
					loading={loading}
					onChange={handleUpdateValue}
					onInputChange={handleUpdateFilteredWarehouse}
					options={filteredWarehouses.map((item) => item?.Description)}
				/>
			</div>
		);
	},
);

WarehousePicker.propTypes = {
	warehouses: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
	warehouse: PropTypes.instanceOf(Object).isRequired,
	setWarehouse: PropTypes.func.isRequired,
	deliveryCity: PropTypes.string.isRequired,
};
