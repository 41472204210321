import { types } from 'mobx-state-tree';
import ProductAttribute from '../../pureModels/attribute-model';

export const CartProduct = types.model('CartProduct', {
	id: types.maybeNull(types.identifierNumber),
	title: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
	price: types.maybeNull(types.number),
	discountPrice: types.maybeNull(types.number),
	quantity: types.maybeNull(types.number),
	stockControl: types.optional(types.boolean, true),
	inStock: types.maybeNull(types.number),
	image: types.maybeNull(types.string),
	variantParentId: types.maybeNull(types.number),
	attributes: types.maybeNull(types.array(ProductAttribute)),
});

const CartItem = types.model('CartItem', {
	id: types.maybeNull(types.number),
	quantity: types.maybeNull(types.number),
});

export const Cart = types.model('Cart', {
	id: types.maybeNull(types.identifierNumber),
	cart: types.optional(types.array(types.maybeNull(CartItem)), []),
});
