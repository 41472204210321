import { useState } from 'react';
import { toJS } from 'mobx';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { InputAutocomplete } from '../../../../components/shared/InputAutocomplete';
import { useStores } from '../../../../stores/context';
import './index.scss';

export const Settlement = observer(({ settlement, setSettlement }) => {
	const { t } = useTranslation();
	const {
		novaPoshtaStore: { loading, settlements, getSettlements, resetWarehouses },
	} = useStores();

	const [showPicker, setShowPicker] = useState(false);

	const rawSettlements = toJS(settlements);

	return (
		<div className="settlements">
			<button
				type="button"
				className="settlements__info"
				onClick={() => setShowPicker(!showPicker)}
			>
				<div className="settlements__location">
					<PlaceOutlinedIcon />
					{settlement.Present ? (
						<div className="settlements__settlement">
							<p className="settlements__city">
								{`${settlement.SettlementTypeCode} ${settlement.MainDescription}`}
							</p>
							<p className="settlements__region">
								{`${settlement.Area} ${settlement.ParentRegionCode}`}
							</p>
						</div>
					) : (
						t('delivery.settlement.select')
					)}
				</div>
				{showPicker ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
			</button>
			{showPicker ? (
				<InputAutocomplete
					value={settlement.Present}
					loading={loading}
					onChange={(newValue) => {
						setSettlement(
							rawSettlements.find((item) => item.Present === newValue),
						);
						resetWarehouses();
						setShowPicker(false);
					}}
					onInputChange={(newInputValue) => {
						if (newInputValue.length) getSettlements(newInputValue);
					}}
					options={rawSettlements.map((item) => item.Present)}
				/>
			) : null}
		</div>
	);
});

Settlement.propTypes = {
	settlement: PropTypes.instanceOf(Object).isRequired,
	setSettlement: PropTypes.func.isRequired,
};
