import { types } from 'mobx-state-tree';

const ImageModel = types.model('Image', {
	id: types.identifierNumber,
	regularUrl: types.maybeNull(types.string),
	originalUrl: types.maybeNull(types.string),
	thumbnailUrl: types.maybeNull(types.string),
});

export { ImageModel };
