import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { observer } from 'mobx-react';
import {
	categoryTitleFormater,
	formatPrice,
} from '../../../../../utils/helpers';
import { useStores } from '../../../../../stores/context';
import ImageLoader from '../../../../shared/ImageWithLoader';
import './index.scss';

export const SearchSuggest = observer(
	({
		searchInputText,
		setSearchInputText,
		searchResult,
		handleCancelSearchInput,
	}) => {
		const {
			productsStore: { setSearchQuery, resetSearchQuery },
			shopsStore: {
				currentShop: { currencySymbol },
			},
		} = useStores();
		const { t } = useTranslation();
		const { telegramBotUsername } = useParams();

		const handleReset = () => {
			resetSearchQuery();
			handleCancelSearchInput();
		};

		const handleShowAllResults = () => {
			handleReset();
			setSearchQuery({ title: searchInputText, page: 1 });
		};

		const handleOpenCategoryPage = () => {
			handleReset();
			setSearchInputText('');
		};

		return (
			<div className="search-suggest">
				{searchResult.items.length > 0 && searchInputText.length > 0 ? (
					<ul className="search-suggest__list">
						<li className="search-suggest__item">
							<Link
								className="search-suggest__show-all"
								to={`/s/${telegramBotUsername}/`}
								onClick={handleShowAllResults}
							>
								{t('search-suggest.show-all')}&nbsp;→
							</Link>
						</li>
						{searchResult.items.map((item) => {
							return (
								<li key={item.id} className="search-suggest__item">
									<Link
										className="suggest-goods"
										to={`/s/${telegramBotUsername}/${item.id}`}
										onClick={handleReset}
										title={item.title}
									>
										<span className="suggest-goods__image">
											<ImageLoader images={item.images || []} />
										</span>
										<span className="suggest-goods__info">
											<span className="suggest-goods__title">{item.title}</span>
											<span className="suggest-goods__price">
												{`${formatPrice(
													item.discountPrice || item.price,
												)} ${currencySymbol}`}
											</span>
										</span>
									</Link>
								</li>
							);
						})}
						{searchResult.categories.length > 0 && (
							<>
								<li className="search-suggest__item">
									<p className="search-suggest__heading">
										{t('search-suggest.open-category')}
									</p>
									<ul className="search-suggest__group">
										{searchResult.categories.map((category) => (
											<li key={category.id} className="search-suggest__item">
												<Link
													to={`/s/${telegramBotUsername}/`}
													state={category}
													onClick={handleOpenCategoryPage}
												>
													<span className="search-suggest__item-content">
														<CategoryOutlinedIcon />
														<span>
															{categoryTitleFormater(category?.title, t)}
														</span>
													</span>
												</Link>
											</li>
										))}
									</ul>
								</li>
								<li className="search-suggest__item">
									<p className="search-suggest__heading">
										{t('search-suggest.search-in-category')}
									</p>
									<ul className="search-suggest__group">
										{searchResult.categories.map((category) => (
											<li key={category.id} className="search-suggest__item">
												<Link
													to={`/s/${telegramBotUsername}/`}
													state={category}
													onClick={handleShowAllResults}
												>
													<span className="search-suggest__item-content">
														<SearchRoundedIcon />
														<div className="search-suggest__item-caption">
															<span>{t('search-suggest.caption')}&nbsp;</span>
															<span>
																{categoryTitleFormater(category?.title, t)}
															</span>
														</div>
													</span>
												</Link>
											</li>
										))}
									</ul>
								</li>
							</>
						)}
					</ul>
				) : (
					<p className="search-suggest__item--no-result">
						{t('search-form.no-result')}
						<br />
						<nobr>{t('search-form.clarify')}</nobr>
					</p>
				)}
			</div>
		);
	},
);

SearchSuggest.defaultProps = {
	searchInputText: '',
	searchResult: { items: [], categories: [] },
};

SearchSuggest.propTypes = {
	searchInputText: PropTypes.string,
	setSearchInputText: PropTypes.func.isRequired,
	searchResult: PropTypes.instanceOf(Object),
	handleCancelSearchInput: PropTypes.func.isRequired,
};
